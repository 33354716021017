import { CurveSource, PointSource, primitives } from "cadius-components";
import {
  adaptFlattenedBoundaries,
  BackMiddleEdgePID,
  ConeAndFeatherPaths,
  ConeEdgePID,
  convertBoundaryPolyLinesToSplines,
  createConeEdgeFromPath,
  createFeatherEdgeFromPath,
  extractFundamentalBoundaries2D,
  FeatherEdgeExteriorPID,
  FeatherEdgeInteriorPID,
  FeatherEdgePID,
  FrontMiddleEdgePID,
  LatitudeAndLongitudePaths,
  makeConeAndFeatherPaths,
  makeGrid,
  makeLastFromGrid,
  makeLatitudeAndLongitudePaths,
  ProjectionSurface,
  SplinePath
} from "cadius-db";
import { Parameter, roundToDecimals } from "cadius-geo";
import { CppRemeshedLast } from "cadius-geo3d";
import { time } from "cadius-stdlib";
import { Vector3 } from "three";

import { remeshingPalette } from "../palettes";
import { FlattenCurves } from "../reducers/support/flatten";

/**
 * Aggregation of data returned by `computeGrid()`:
 * - latitudeAndLongitudePaths aggregates the paths representing the grid for the two foot-upper halves
 * - coneAndFeatherPaths aggregates the paths representing the grid in the feather and the cone parts
 * - featherLength is the length of the feather from the tip to the back of the feather-edge, passing
 *   through the symmetry plane
 * - gridLines is the list of curve sources, one for each path in `latitudeAndLongitudePaths` and `coneAndFeatherPaths`
 * - throat is the point source to render the position of the throat point on the Last
 * - tip is the point source to render the position of the tip on the Last.
 * @export
 * @interface GridResult
 */
export interface GridResult {
  latitudeAndLongitudePaths: LatitudeAndLongitudePaths;
  coneAndFeatherPaths: ConeAndFeatherPaths;
  featherLength: number;
  gridLines: CurveSource[];
  throat: PointSource;
  tip: PointSource;
}

export const featherLengthPrecision = 2;

/**
 * The computeGrid function takes in the the two fundamental curves, feather-edge and cone-edge, and make the remeshig
 * grid.
 * The grid is a list of paths intersecting each other forming a net of quadrilateral faces. It is used for remeshing
 * the Last.
 * The grid is stored into the `latitudeAndLongitudePaths` and the `coneAndFeatherPaths`. Its graphical representation
 * is stored into `gridLines`. For other data see `GridResult`.
 * @export
 * @param {SplinePath} featherSplinePath The spline path representing the feather-edge.
 * @param {SplinePath} coneSplinePath The spline path representing the cone-edge.
 * @param {number} tipThroatDistance The (initiall) geodesic distance between the tip and the throat. It is used to pose
 * the graphical point source for the throat point.
 * @returns {GridResult} The aggregation of the grid paths and its graphical representation.
 */
export function computeGrid(
  featherSplinePath: SplinePath,
  coneSplinePath: SplinePath,
  tipThroatDistance: number
): GridResult {
  const t = time("creating remeshing grid");
  const featherEdge = createFeatherEdgeFromPath(FeatherEdgePID, featherSplinePath);
  if (!featherEdge) {
    throw new Error("ERROR: unable to create feather edge");
  }
  const coneEdge = createConeEdgeFromPath(ConeEdgePID, coneSplinePath);
  if (!coneEdge) {
    throw new Error("ERROR: unable to create cone edge");
  }
  const latitudeAndLongitudePaths = makeLatitudeAndLongitudePaths(featherEdge, coneEdge);
  const coneAndFeatherPaths = makeConeAndFeatherPaths(
    featherEdge,
    coneEdge,
    latitudeAndLongitudePaths
  );
  t.stop();

  const featherSplitPath = coneAndFeatherPaths.featherSplitPath;
  const projectManager = featherEdge.projectManager();
  const pid = projectManager.newPID();
  const mark = projectManager.newMark(pid);
  const approxFeatherDiameterPath = new SplinePath(
    pid,
    mark,
    featherSplitPath.projectionSurface(),
    featherSplitPath.controlPoints.map((c) => new Vector3(c.x, c.y, 0)),
    false
  );
  const featherLength = roundToDecimals(approxFeatherDiameterPath.geometry().length(), featherLengthPrecision);

  const gridLines = new Array<CurveSource>();
  const copts: primitives.COptions = { style: "Spline", params: { linewidth: 1 } };
  gridLines.push(...latitudeAndLongitudePaths.latitudePaths.map((c) => new CurveSource(c, copts)));
  gridLines.push(...latitudeAndLongitudePaths.exteriorLongitudePaths.map((c) => new CurveSource(c, copts)));
  gridLines.push(...latitudeAndLongitudePaths.interiorLongitudePaths.map((c) => new CurveSource(c, copts)));
  gridLines.push(new CurveSource(latitudeAndLongitudePaths.backMiddleEdge, copts));
  gridLines.push(new CurveSource(latitudeAndLongitudePaths.frontMiddleEdge, copts));

  gridLines.push(new CurveSource(coneAndFeatherPaths.featherSplitPath, copts));
  gridLines.push(...coneAndFeatherPaths.featherLatitudePaths.map((c) => new CurveSource(c, copts)));
  gridLines.push(...coneAndFeatherPaths.featherLongitudePaths.map((c) => new CurveSource(c, copts)));

  gridLines.push(new CurveSource(coneAndFeatherPaths.coneSplitPath, copts));
  gridLines.push(...coneAndFeatherPaths.coneLatitudePaths.map((c) => new CurveSource(c, copts)));
  gridLines.push(...coneAndFeatherPaths.coneLongitudePaths.map((c) => new CurveSource(c, copts)));

  const frontGeometry = latitudeAndLongitudePaths.frontMiddleEdge.geometry();
  const throat = new PointSource(
    frontGeometry.point(
      frontGeometry.parameterFromLength(Math.max(frontGeometry.length() - tipThroatDistance, 0))
    ),
    { color: remeshingPalette.get("throat")! });
  const tip = new PointSource(frontGeometry.point(new Parameter(1)), { color: remeshingPalette.get("tip")! });

  return { coneAndFeatherPaths, featherLength, latitudeAndLongitudePaths, gridLines, throat, tip };
}

/**
 * @brief The result of `remeshAndFlattenLast`. It contains the remeshed last and its flattening curves.
 */
export interface RemeshResult {
  remeshedLast: CppRemeshedLast;
  bootstrap: FlattenCurves;
}

/**
 * @brief Creates a new remeshed last and its flattening curves from the given remeshing grid.
 *
 * @param bootstrapProjectionSurface The projection surface where the computed flattening curve are projected onto;
 * @param coneAndFeatherPaths The remeshing grid for the cone and feather;
 * @param latitudeAndLongitudePaths The remeshing grid for the foot-upper;
 * @param tipThroatDistance The distance from `tip` to `throat` point in mm.
 * @returns a `RemeshedResult` composed of a remeshed last and its flattening curves.
 */
export function remeshAndFlattenLast(
  bootstrapProjectionSurface: ProjectionSurface,
  coneAndFeatherPaths: ConeAndFeatherPaths,
  latitudeAndLongitudePaths: LatitudeAndLongitudePaths,
  tipThroatDistance: number
): RemeshResult {
  const projectManager = bootstrapProjectionSurface.projectManager();
  let t = time("creating remeshed last");
  const remeshedLast = makeLastFromGrid(makeGrid(
    latitudeAndLongitudePaths,
    coneAndFeatherPaths
  ));
  t.stop();
  t = time("extracting bootstrap curves");
  const polylines2D = adaptFlattenedBoundaries(
    extractFundamentalBoundaries2D(remeshedLast),
    tipThroatDistance,
    false
  );
  const splines2D = convertBoundaryPolyLinesToSplines(polylines2D);
  const bootstrap = new FlattenCurves(
    new SplinePath(
      ConeEdgePID,
      projectManager.newMark(ConeEdgePID),
      bootstrapProjectionSurface,
      splines2D.cone.controlPoints.map((p) => new Vector3(p.x, p.y)),
      splines2D.cone.isLoop
    ),
    new SplinePath(
      BackMiddleEdgePID,
      projectManager.newMark(BackMiddleEdgePID),
      bootstrapProjectionSurface,
      splines2D.back.controlPoints.map((p) => new Vector3(p.x, p.y)),
      splines2D.back.isLoop
    ),
    new SplinePath(
      FeatherEdgeInteriorPID,
      projectManager.newMark(FeatherEdgeInteriorPID),
      bootstrapProjectionSurface,
      splines2D.interior.controlPoints.map((p) => new Vector3(p.x, p.y)),
      splines2D.interior.isLoop
    ),
    new SplinePath(
      FeatherEdgeExteriorPID,
      projectManager.newMark(FeatherEdgeExteriorPID),
      bootstrapProjectionSurface,
      splines2D.exterior.controlPoints.map((p) => new Vector3(p.x, p.y)),
      splines2D.exterior.isLoop
    ),
    new SplinePath(
      FrontMiddleEdgePID,
      projectManager.newMark(FrontMiddleEdgePID),
      bootstrapProjectionSurface,
      splines2D.front.controlPoints.map((p) => new Vector3(p.x, p.y)),
      splines2D.front.isLoop
    ),
  );
  t.stop();
  return { remeshedLast, bootstrap };
}
