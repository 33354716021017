import { isReactiveCadiusSceneWithRenderManager, ReactiveView } from "cadius-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  onKeyDown,
  onMouseDown,
  onMouseMove,
  onMouseUp,
  onWheel
} from "../actions/events";
import { CadiusDispatch } from "../actions/interfaces";
import { setCameraSize } from "../actions/ui";
import { FlattenModel as FlattenModelImpl } from "../components/FlattenModel";
import { IApplicationState } from "../reducers/interfaces";

function mapStateToProps(state: IApplicationState) {
  const { cadView, scene } = state.flattenModel.view;
  if (isReactiveCadiusSceneWithRenderManager(scene)) {
    throw new Error("ASSERT: the form-app should use the old ReactiveCadiusScene");
  }

  const flatteningRefURL = state.flattenModel.flatImage ? state.flattenModel.flatImage.src : undefined;
  return { cadView, flatteningRefURL, scene };
}

function mapDispatchToProps(dispatch: CadiusDispatch) {
  const actionCreators = bindActionCreators(
    {
      onKeyDown,
      onMouseDown,
      onMouseMove,
      onMouseUp,
      onWheel,
    },
    dispatch
  );
  return {
    ...actionCreators,
    onResize: (cadView: ReactiveView, width: number, height: number) => {
      dispatch(setCameraSize(cadView, width, height));
    },
  };
}

const enhance = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const FlattenModel = enhance(FlattenModelImpl);
