import { Reducer } from "redux";

import { Render3DContext, Render3DContextFilters } from "cadius-cadlib";

import { IAction } from "../actions/interfaces";
import { UPDATE_GLOBAL_FEEDBACK } from "../actions/ui";
import { IUIState } from "./interfaces";

export const reducer: Reducer<IUIState, IAction> = (
  state: IUIState = initialState(),
  action: IAction
): IUIState => {
  switch (action.type) {
    case UPDATE_GLOBAL_FEEDBACK:
      return {
        ...state,
        feedback: action.payload,
      };

    default:
      return state;
  }
};

export const initialState = (): IUIState => {
  const feedback = { open: false, title: "" };

  const filters: Render3DContextFilters = {
    showDecorations: true,
    showLast: true,
    showLines: true,
    showPieces: true,
    showWireframe: false,
  };

  const ctx: Render3DContext = {
    debug: { objects: [] },
    envMaps: null,
    filters,
    refresh: () => Promise.resolve(),
  };

  return { feedback, ctx };
}
