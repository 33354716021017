import {
  CameraControllerInteractor,
  CurveStoreValue,
  DEFAULT_SNAP_RADIUS_PX,
  EditCurveInteractor,
  IView,
} from "cadius-components";
import { convertArray2DToTypedArray, extractFundamentalBoundaries2D, SplinePath } from "cadius-db";
import { FlatteningBoundaries } from "cadius-geo3d";
import { Vector2, Vector3 } from "three";

import { FormsAppControl, StoreSignature } from "../controls";
import { AlignmentFlatteningStoreType, FlatteningPointsInteractor } from "../interactors/flattening-point";
import { flatteningPalette } from "../palettes";
import { FlattenMode, IApplicationState } from "../reducers/interfaces";
import { FlatCurvesNames } from "../reducers/support/flatten";
import { resetInteractors, startInteractor } from "./interactor";
import { CadiusDispatch, CadiusThunkAction, IAction } from "./interfaces";
import { sceneSourceRefresh } from "./render";
import { saveProjectOnBackend } from "./save-project";

/**
 * @brief A list of all possible flattening curve.
 */
export const flatCurveNames: FlatCurvesNames[] = ["cone", "back", "exterior", "interior", "front"];

/**
 * @brief The callback used by the CameraController interactor when acting on the view of the flattening phase.
 *
 * This callback takes the view that is the result the camera controller and stores it in the relative view of the
 * flattening phase.
 *
 * @param state The application state;
 * @param newView The updated view.
 */
export const cameraControllerFlattenModelStore: StoreSignature<IView> = (
  state: IApplicationState,
  view: IView
): IApplicationState => {
  return {
    ...state,
    flattenModel: {
      ...state.flattenModel,
      view,
    },
  };
}

/**
 * @brief The storing callback for the EditCurveInteractor in the flattening phase. It saves a flattening curve in the
 * correct state field.
 *
 * @param state The applicationState;
 * @param value contains both the curve set by this function and its identifier (that is passed by the application to
 * the curve interactor at contruction time).
 */
export const storeFlatteningCurve: StoreSignature<CurveStoreValue> = (
  state: IApplicationState,
  value: CurveStoreValue,
  dispatch: CadiusDispatch
): IApplicationState => {
  const identifier = value.identifier as FlatCurvesNames;
  const originalSpline = state.flattenModel.curves[identifier];
  setTimeout(async () => {
    if (!originalSpline || originalSpline.spline !== value.spline) {
      await dispatch(checkAndSetFlatteningCurve(identifier, value.spline));
      dispatch(sceneSourceRefresh());
      dispatch(saveProjectOnBackend());
    }
    dispatch(setFlatteningCurvesVisibility(flatCurveNames, true));
  }, 0);
  return state;
};

/**
 * @brief The store function that saves the alignment points for the flattening phase in the application state and
 * applies the given rotation to the flattening image.
 *
 * It also updates the flattening view since the `PointEditorInteractor` instance has just been removed by the stack and
 * its rendered objects needs to be removed.
 *
 * @param state The application state;
 * @param value An object composed of a pair of 2D points that represent the throat and tip respectively and an angle to
 * rotate the flattening image.
 */
export const flatteningPointStore: StoreSignature<AlignmentFlatteningStoreType> = (
  state: IApplicationState,
  value: AlignmentFlatteningStoreType,
  dispatch: (a: any) => void
): IApplicationState => {
  setTimeout(() => {
    dispatch(setFlatteningAlignmentPoints(undefined, value.points[1]));
    if (value.angle) {
      dispatch(setFlatteningImageOrientation(value.angle));
    }
    dispatch(setFlatteningAlignmentPoints(value.points[0], value.points[1]));
    dispatch(sceneSourceRefresh());
  }, 0);
  return state;
}

const PREFIX = "[step-3] FLATTEN MODEL";

/**
 * @brief Dispatched once the image url has been successfully converted to an image.
 *
 * The payload is
 * ```ts
 * {
 *   image: HTMLImageElement,
 *   refreshCallback: () => void,
 * }
 * ```
 * where `image` is an `HTMLImageElement` representing the flattening image and `refreshCallback` is a callback that is
 * called once the image loading has completed.
 */
export const SET_FLATTENING_IMAGE = `${PREFIX} SET_FLATTENING_IMAGE`;

/**
 * @brief Dispatched when the user has set the two alignment points used to align the automatic parameterization to the
 * manual one.
 *
 * The payload is
 * ```ts
 * {
 *   throat,
 *   tip,
 * }
 * ```
 * where both `throat` and `tip` are `Vector2`s that contain the coordinates of the throat point and tip point,
 * respectively.
 */
export const SET_FLATTENING_ALIGNMENT_POINTS = `${PREFIX} SET_FLATTENING_ALIGNMENT_POINTS`;

/**
 * @brief This actions ask the reducer to set line provided.
 *
 * An action with this type is dispatched at the end of the line editing.
 *
 * The payload is:
 * ```ts
 * {
 *   curve,
 *   path,
 * }
 * ```
 * where `curve` is the name of the fundamental flattening curve and `path` is a `SplinePath` representing the new
 * curve.
 */
export const SET_FLATTENING_CURVE = `${PREFIX} SET_FLATTENING_CURVE`;

/**
 * @brief This action is dispatched when the application changes the visibility attribute of a flattening curve.
 *
 * The payload is:
 * ```ts
 * {
 *   curve: FlatCurvesNames,
 *   visible: boolean,
 * }
 * ```
 * where `curve` is the curve to change and `visible` the new value for its visibility attribute.
 */
export const SET_FLATTENING_CURVE_VISIBILITY = `${PREFIX} SET_FLATTENING_CURVE_VISIBILITY`;

/**
 * @brief This action changes the orientation of the flattening image by rotation it around the Z-axis at the `tip`
 * point.
 *
 * The payload is:
 * ```ts
 * {
 *    angle: number,
 * }
 * ```
 * where `angle` is the desired orientation for the flattening image.
 */
export const SET_FLATTENING_IMAGE_ORIENTATION = `${PREFIX} SET_FLATTENING_IMAGE_ORIENTATION`;

/**
 * @brief Dispatched when the user activates one of the sub-mode of the flattening process.
 *
 * The payload is:
 * ```ts
 * {
 *   mode: "align" | "draw"
 * }
 * ```
 */
export const SET_FLATTENING_MODE = `${PREFIX} SET_FLATTENING_MODE`;

/**
 * @brief Dispatched when the user decides that the flattening curves are good and wants to
 * impose them to reparameterize the remeshedLast's foot-upper halves.
 *
 * The payload is empty, as the action creator is responsible to compute the parameterization
 * in-place.
 */
export const IMPOSE_FLATTENING_BOUNDARIES = `${PREFIX} IMPOSE_FLATTENING_BOUNDARIES`;

/**
 * @brief changes the Flat Image. This action also reset the flattening state.
 */
export function setFlatteningImage(image: HTMLImageElement): CadiusThunkAction<void> {
  return async (dispatch: CadiusDispatch, getState: () => IApplicationState) => {
    await image.decode();

    // It doesn't matter if the following callback is called by the reducer, sceneSourceRefresh is an async action
    const refreshCallback = () => {
      dispatch(sceneSourceRefresh());
    };

    if (getState().flattenModel.mode !== FlattenMode.align) {
      dispatch(changeFlatteningMode(FlattenMode.align));
    }

    dispatch({ payload: { image, refreshCallback }, type: SET_FLATTENING_IMAGE });

    dispatch(resetInteractors([
      new CameraControllerInteractor(
        "CameraControllerFlattenModel",
        new FormsAppControl(cameraControllerFlattenModelStore, dispatch)
      ),
      new FlatteningPointsInteractor(
        getState().flattenModel.dashboard.canvas,
        new FormsAppControl(flatteningPointStore, dispatch)
      )
    ]));
  };
}

export function setFlatteningImageOrientation(angle: number): IAction {
  return {
    payload: { angle },
    type: SET_FLATTENING_IMAGE_ORIENTATION,
  };
}

// set throat and tip point, the resulting entities are aligned so that the line between the points is parallel to the
// x-axis
export function setFlatteningAlignmentPoints(throat?: Vector3, tip?: Vector3): IAction {
  return {
    payload: {
      throat,
      tip,
    },
    type: SET_FLATTENING_ALIGNMENT_POINTS,
  };
}

export function setFlatteningCurvesVisibility(curves: FlatCurvesNames[], visible: boolean): IAction {
  return {
    payload: { curves, visible },
    type: SET_FLATTENING_CURVE_VISIBILITY,
  };
}

/**
 * @brief start the edit of a flattening line so that the user can change it.
 *
 * @param curveName the name of the curve to edit;
 * @param spline a curve used instead of retriving the spline from the curve in the current state. By providing a
 * spline, this action creator can be used by `newFlatteningCurve` conveniently.
 */
export function startFlatteningCurveEdit(curveName: FlatCurvesNames, spline?: SplinePath): CadiusThunkAction<void> {
  return async (dispatch: CadiusDispatch, getState: () => IApplicationState) => {
    const curves = getState().flattenModel.curves;

    if (!spline) {
      if (!curves) {
        throw new Error("ASSERT: no flattening curve set");
      }

      const curve = curves[curveName];
      if (!curve) {
        throw new Error("ASSERT: unable to find flattening curve for " + curveName);
      }
      spline = curve.spline;
    }

    const control = new FormsAppControl(storeFlatteningCurve, dispatch);
    const color = flatteningPalette.get(curveName)!;
    dispatch(setFlatteningCurvesVisibility(flatCurveNames, false));
    dispatch(startInteractor(new EditCurveInteractor(
      getState().flattenModel.dashboard.canvas,
      curveName,
      spline,
      control,
      DEFAULT_SNAP_RADIUS_PX,
      color,
      (idx: number, length: number) => idx !== 0 && idx !== length,
      (idx: number, length: number) => idx !== 0 && idx !== length - 1,
      false
    )));
    dispatch(sceneSourceRefresh());
  };
}

/**
 * The type of the endpoint description: it is used to specify which curve endpoint to get access to, either the first
 * or the last, in the following `checkAndSetFlatteningCurve()` thunk action creator.
 */
type EndPointTag = "first" | "last";

/**
 * This interface represents an object description of a curve dependent of another one, together with an indication of
 * which endpoints are related.
 * @interface CurveDependency
 */
interface CurveDependency {
  /**
   * Name of the adjacent curve.
   * @type {FlatCurvesNames}
   * @memberof CurveDependency
   */
  adjacent: FlatCurvesNames;

  /**
   * Specification of which endpoint on the free curve (the one the `adjacent` curve depends on) is the connection:
   * either the first or the last.
   * @type {EndPointTag}
   * @memberof CurveDependency
   */
  endPoint: EndPointTag;

  /**
   * Specification of which endpoint on the adjacent curve is the connection: either the first or the last.
   * @type {EndPointTag}
   * @memberof CurveDependency
   */
  adjacentEndPoint: EndPointTag;
}

/**
 * Mapped-type having a description of which curves are dependent for each of the five fundamental curves.
 */
type CurveDependencies = {
  [curve in FlatCurvesNames]: CurveDependency[];
};

/**
 * This object maps a fundamental curve name with the description of which other fundamental curves are dependent,
 * together with which endpoint are connected.
 */
const curveDependencies: CurveDependencies = {
  back: [
    {
      adjacent: "cone",
      adjacentEndPoint: "last",
      endPoint: "first",
    },
    {
      adjacent: "exterior",
      adjacentEndPoint: "first",
      endPoint: "last",
    },
    {
      adjacent: "interior",
      adjacentEndPoint: "first",
      endPoint: "last",
    },
  ],
  cone: [
    {
      adjacent: "front",
      adjacentEndPoint: "last",
      endPoint: "first",
    },
    {
      adjacent: "back",
      adjacentEndPoint: "first",
      endPoint: "last",
    },
  ],
  exterior: [
    {
      adjacent: "back",
      adjacentEndPoint: "last",
      endPoint: "first",
    },
    {
      adjacent: "interior",
      adjacentEndPoint: "first",
      endPoint: "first",
    },
    {
      adjacent: "front",
      adjacentEndPoint: "first",
      endPoint: "last",
    },
    {
      adjacent: "interior",
      adjacentEndPoint: "last",
      endPoint: "last",
    },
  ],
  front: [
    {
      adjacent: "exterior",
      adjacentEndPoint: "last",
      endPoint: "first",
    },
    {
      adjacent: "interior",
      adjacentEndPoint: "last",
      endPoint: "first",
    },
    {
      adjacent: "cone",
      adjacentEndPoint: "first",
      endPoint: "last",
    },
  ],
  interior: [
    {
      adjacent: "back",
      adjacentEndPoint: "last",
      endPoint: "first",
    },
    {
      adjacent: "exterior",
      adjacentEndPoint: "first",
      endPoint: "first",
    },
    {
      adjacent: "front",
      adjacentEndPoint: "first",
      endPoint: "last",
    },
    {
      adjacent: "exterior",
      adjacentEndPoint: "last",
      endPoint: "last",
    },
  ],
};

/**
 * Thunk action creator to set the new fundamental flattening curve named `curveName` with entity `path`.
 * This action creator also checks whether such curve has got different endpoints than the adjacent ones, in such
 * case those are replaced too, by replacing them with others such that their corresponding endpoint is then matched.
 *
 * The adjacency is here listed:
 * - for the *back*:
 *     - at the first endpoint:
 *         - the *cone* at the last endpont
 *     - at the last endpoint:
 *         - the *exterior* at the first endpont
 *         - the *interior* at the first endpont
 * - for the *cone*:
 *     - at the first endpoint:
 *         - the *front* at the last endpoint
 *     - at the last endpoint:
 *         - the *back* at the first endpoint
 * - for the *exterior*:
 *     - at the first endpoint:
 *         - the *back* at the last endpoint
 *         - the *interior* at the first endpoint
 *     - at the last endpoint:
 *         - the *front* at the first endpoint
 *         - the *interior* at the last endpoint
 * - for the *front*:
 *     - at the first endpoint:
 *         - the *exterior* at the last endpoint
 *         - the *interior* at the last endpoint
 *     - at the last endpoint:
 *         - the *cone* at the first endpoint
 * - for the *interior*:
 *     - at the first endpoint:
 *         - the *back* at the last endpoint
 *         - the *exterior* at the first endpoint
 *     - at the last endpoint:
 *         - the *front* at the first endpoint
 *         - the *exterior* at the last endpoint.
 * @export
 * @param {FlatCurvesNames} curveName The name of the fundamental curve to set.
 * @param {SplinePath} path The replacing curve.
 * @returns {CadiusThunkAction<void>}
 */
export function checkAndSetFlatteningCurve(curveName: FlatCurvesNames, path: SplinePath): CadiusThunkAction<void> {
  return async (dispatch: CadiusDispatch, getState: () => IApplicationState) => {
    dispatch(setFlatteningCurve(curveName, path));

    const curves = getState().flattenModel.curves;

    const dependencies = curveDependencies[curveName];

    type SplinesToReplace = Partial<{
      [name in FlatCurvesNames]: { spline: SplinePath };
    }>;
    const toReplace: SplinesToReplace = {};

    for (const { adjacent, adjacentEndPoint, endPoint } of dependencies) {
      const adjacentCurve = toReplace[adjacent] || curves[adjacent];
      if (adjacentCurve) {
        if (endPoint === "first") {
          if (adjacentEndPoint === "first") {
            const cp = path.controlPoints[0];
            const acp = adjacentCurve.spline.controlPoints[0];
            if (!acp.equals(cp)) {
              const controlPoints = [cp.clone(), ...adjacentCurve.spline.controlPoints.slice(1)];
              toReplace[adjacent] = {
                spline: adjacentCurve.spline.clone({ controlPoints }),
              };
            }
          } else {
            const cp = path.controlPoints[0];
            const acp = adjacentCurve.spline.controlPoints[adjacentCurve.spline.controlPoints.length - 1];
            if (!acp.equals(cp)) {
              const controlPoints = [...adjacentCurve.spline.controlPoints.slice(0, -1), cp.clone()];
              toReplace[adjacent] = {
                spline: adjacentCurve.spline.clone({ controlPoints }),
              };
            }
          }
        } else {
          if (adjacentEndPoint === "first") {
            const cp = path.controlPoints[path.controlPoints.length - 1];
            const acp = adjacentCurve.spline.controlPoints[0];
            if (!acp.equals(cp)) {
              const controlPoints = [cp.clone(), ...adjacentCurve.spline.controlPoints.slice(1)];
              toReplace[adjacent] = {
                spline: adjacentCurve.spline.clone({ controlPoints }),
              };
            }
          } else {
            const cp = path.controlPoints[path.controlPoints.length - 1];
            const acp = adjacentCurve.spline.controlPoints[adjacentCurve.spline.controlPoints.length - 1];
            if (!acp.equals(cp)) {
              const controlPoints = [...adjacentCurve.spline.controlPoints.slice(0, -1), cp.clone()];
              toReplace[adjacent] = {
                spline: adjacentCurve.spline.clone({ controlPoints }),
              };
            }
          }
        }
      }
    }

    for (const name in toReplace) {
      if (name in toReplace) {
        const newCurveName = name as FlatCurvesNames;
        const newCurve = toReplace[newCurveName];
        if (newCurve) {
          dispatch(setFlatteningCurve(newCurveName, newCurve.spline));
        }
      }
    }
  };
}

/**
 * @brief changes a flattening line, this action also change the lines near `curveName` so that they are still
 * connected.
 *
 * @param curveName The id for the curve to set;
 * @param path The new spline.
 */
export function setFlatteningCurve(curveName: FlatCurvesNames, path: SplinePath): IAction {
  return {
    payload: {
      curve: curveName,
      path,
    },
    type: SET_FLATTENING_CURVE,
  };
}

/**
 * @brief changes the flattening line using a "default" value; such value is a straight line that goes from the previous
 * to the next line and start editing it.
 *
 * @param curveName The name of the curve to draw.
 */
export function newFlatteningCurve(curveName: FlatCurvesNames): CadiusThunkAction<void> {
  return async (dispatch: CadiusDispatch, getState: () => IApplicationState) => {
    const dependencies = curveDependencies[curveName];
    const curves = getState().flattenModel.curves;

    const prevDep = dependencies.find((curveDep) => curveDep.endPoint === "first")!;
    const prevCurve = curves[prevDep.adjacent];
    if (!prevCurve) {
      throw new Error("ASSERT: unable to compute previous curve");
    }
    const newBegin = prevCurve.spline.controlPoints[
      prevDep.adjacentEndPoint === "first" ? 0 : prevCurve.spline.controlPoints.length - 1
    ].clone();

    const nextDep = dependencies.find((curveDep) => curveDep.endPoint === "last")!;
    const nextCurve = curves[nextDep.adjacent];
    if (!nextCurve) {
      throw new Error("ASSERT: unable to compute next curve");
    }
    const newEnd = nextCurve.spline.controlPoints[
      nextDep.adjacentEndPoint === "first" ? 0 : nextCurve.spline.controlPoints.length - 1
    ].clone();

    const oldCurve = curves[curveName];
    if (!oldCurve) {
      throw new Error(`ASSERT: ${curveName} is undefined`);
    }

    const oldSpline = oldCurve.spline;
    const projectManager = oldSpline.projectManager();
    const newMark = projectManager.newMark(oldSpline.pid);
    const spline = new SplinePath(oldSpline.pid, newMark, oldSpline.projectionSurface(), [newBegin, newEnd], false);

    dispatch(setFlatteningCurve(curveName, spline));
    dispatch(startFlatteningCurveEdit(curveName, spline));
  };
}

/**
 * @brief Change the flattening mode to `mode`. This action creator also push the interactor needed for the new mode
 * in the interactor stack.
 */
export function changeFlatteningMode(mode: FlattenMode): CadiusThunkAction<void> {
  return async (dispatch: CadiusDispatch, getState: () => IApplicationState) => {
    const state = getState();
    if (state.flattenModel.mode === mode) {
      throw new Error(
        `ASSERT: trying to change flattening mode to ${mode}, but the current mode is already set to ${mode}`
      );
    }

    // remove all interactors but the camera controller (which is at the bottom of the stack)
    if (state.interactorStack.interactors.length > 1) {
      state.interactorStack.pop(state.interactorStack.interactors[1]);
    }

    if (mode === FlattenMode.align) {
      const { throat, tip } = getState().flattenModel.alignmentPoints;
      if (!throat || !tip) {
        throw new Error("ASSERT: invalid alignment points");
      }
      // When we go back to the align sub-phase, we need the `FlatteningPointInteractor` again.
      dispatch(startInteractor(new FlatteningPointsInteractor(
        state.flattenModel.dashboard.canvas,
        new FormsAppControl(flatteningPointStore, dispatch),
        throat,
        tip
      )));
    }

    dispatch({
      payload: { mode },
      type: SET_FLATTENING_MODE,
    });

    dispatch(saveProjectOnBackend());
  };
}

/**
 * Thunk action creator to set the (possibly modified) flattening curves currently in the state as the new boundaries
 * for the foot-upper halves in the remeshed Last. It then extracts the *final* flattening polylines from the remeshed
 * Last to store in the remeshing project on the backend.
 *
 * This action creator doesn't need to take any parameter, since everything it needs is in the current state.
 *
 * NOTE: the remeshed Last is modified in-place. Also, imposing the new boundaries is a time-consuming operation (it
 * means re-parameterizing the two surface portions) and may fail, throwing an error, consequently the promise may be
 * rejected.
 * @export
 * @returns {CadiusThunkAction<void>}
 */
export function imposeFlatteningBoundaries(): CadiusThunkAction<void> {
  return async (dispatch: CadiusDispatch, getState: () => IApplicationState) => {
    const state = getState();
    if (!state.flattenModel.curves.areFull()) {
      throw new Error("ASSERT: cannot impose full flattening boundary, not all curve are present");
    }
    const back = state.flattenModel.curves.back!;
    const cone = state.flattenModel.curves.cone!;
    const front = state.flattenModel.curves.front!;
    const exterior = state.flattenModel.curves.exterior!;
    const interior = state.flattenModel.curves.interior!;
    const flatteningBoundaries: FlatteningBoundaries = {
      back_middle_edge: convertArray2DToTypedArray(
        back.spline.geodesic().points.map((p) => new Vector2(p.x, p.y))
      ),
      cone_edge: convertArray2DToTypedArray(
        cone.spline.geodesic().points.map((p) => new Vector2(p.x, p.y))
      ),
      exterior_feather_edge: convertArray2DToTypedArray(
        exterior.spline.geodesic().points.map((p) => new Vector2(p.x, p.y))
      ),
      front_middle_edge: convertArray2DToTypedArray(
        front.spline.geodesic().points.map((p) => new Vector2(p.x, p.y))
      ),
      interior_feather_edge: convertArray2DToTypedArray(
        interior.spline.geodesic().points.map((p) => new Vector2(p.x, p.y))
      ),
    };
    state.theModel.remeshedLast!.setFootUpperBoundaries(flatteningBoundaries);

    const newBoundaryPolylines = extractFundamentalBoundaries2D(state.theModel.remeshedLast!);
    dispatch({ payload: newBoundaryPolylines, type: IMPOSE_FLATTENING_BOUNDARIES });
  };
}
