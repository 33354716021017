import { client, RemeshingProject } from "cadius-backend";
import { TriangleSoup, TriangleSoupLast } from "cadius-cadlib";
import { BackMiddleEdgePID, ConeEdgePID, FeatherEdgeExteriorPID, FeatherEdgeInteriorPID, FeatherEdgePID, FrontMiddleEdgePID, IProjectManager, Last, LastPID, SplinePath } from "cadius-db";
import { roundToDecimals } from "cadius-geo";
import { cppTriangleSoupFromStl } from "cadius-geo3d";
import { firstOf } from "cadius-stdlib";
import { Quaternion, Vector3 } from "three";

import { CadiusDispatch, CadiusThunkAction } from "../actions/interfaces";
import { FlattenMode, IApplicationState } from "../reducers/interfaces";
import { convertPath2DToPolyline3D, convertPath3DToPolyline3D } from "../support/backend";
import { computeGrid, GridResult, remeshAndFlattenLast } from "../support/remesh";
import { accumulateModelTransformation, applyModelTransformation } from "./align-model";
import { resetInitialState } from "./app";
import { setProject } from "./backend";
import {
  changeFlatteningMode,
  setFlatteningAlignmentPoints,
  setFlatteningCurve,
  setFlatteningImage,
  setFlatteningImageOrientation,
} from "./flatten-model";
import { INITIAL_MODEL_FAIL, setModel } from "./load-model";
import {
  setFundamentalCurve,
  setGridVisibility,
  setRemeshedLast,
  setRemeshingGrid,
  setTipThroatDistance,
} from "./remesh-model";
import { hideGlobalFeedback, showGlobalFeedback } from "./ui";

/**
 * Thunk action creator that loads a project from the backend.
 *
 * This creator performs most of its process in a promise:
 *  1. the current state is reset, in order to remove from the state anything that could make wrong side effects
 *  2. the project is fetched from the backend
 *  3. when the project has been fetched, the original model is fetched from the backend
 *  4. when the original model has been fetched, it is set in the state
 *  5. the model transformation is read and set in the state
 *  6. the feather-edge and the cone-edge are read and set in the state (these are expressed in the space of the
 *     *untrasformed* model)
 *  7. the model transformation is applied to the model and the, possibly undefined, feather-edge and cone-edge
 *  8. if both the feather-edge and the cone-edge are present, the remeshing grid is computed
 *     - if it fails to compute the grid, it means e.g. any of the two curves doesn't intersect the symmetry plane
 *  9. the distance tip-throat is read and, if present, set in the state
 * 10. if the grid has been computed successfully, try to remesh the model and compute the bootstrap flattening curves
 * 11. if the remeshing succeeds, set the grid visibility to true in the state
 * 12. fetch the flattening image, if present, and set it in the state when ready
 * 13. if present, read the flattening alignment points as well as the image rotation and set them in the state
 * 14. also, if both alignment points are present and if the bootstrap curves have been computed, set the flattening
 *     mode in the state to `FlattenMode.draw`
 * 15. read the five fundamental flattening curves and set them in the state, at least those present
 * 16. finally, set the fetched project in the state so that any action the user performs later, it gets synchronized.
 * @export
 * @param {IProjectManager} projectManager The project manager currently set in the state.
 * @param {string} projectID The id of the project to load.
 * @returns {CadiusThunkAction<void>}
 */
export function loadProject(projectManager: IProjectManager, projectID: string): CadiusThunkAction<void> {
  return async (dispatch: CadiusDispatch, getState: () => IApplicationState): Promise<void> => {
    // important: reset the current state, as it may cause wrong side effects later when loading the project
    dispatch(resetInitialState());

    await dispatch(showGlobalFeedback(`fetching project '${projectID}'...`, "Fetching Project"));
    let project: RemeshingProject;
    try {
      project = await client().remeshes.fetch(projectID);
    } catch (err) {
      dispatch(hideGlobalFeedback());
      dispatch({
        payload: { msg: `cannot retrieve project "${projectID}"` },
        type: INITIAL_MODEL_FAIL,
      });
      return;
    }

    try {
      await dispatch(showGlobalFeedback("fetching the original model...", `Project '${project.name}'`));
      const buf = await client().remeshes.fetchOriginalModel(project);
      const triangleSoup = new TriangleSoup(cppTriangleSoupFromStl(buf));
      const triangleSoupLast = new TriangleSoupLast(triangleSoup);
      const last = new Last(LastPID, projectManager.newMark(LastPID), projectManager, "model", triangleSoupLast);
      dispatch(setModel(last));
    } catch (err) {
      dispatch(hideGlobalFeedback());
      dispatch({
        payload: { msg: `cannot retrieve original model for project "${project.name}"` },
        type: INITIAL_MODEL_FAIL,
      });
      return;
    }

    if (project.original_model_transformation) {
      await dispatch(showGlobalFeedback("loading model transformations...", `Project '${project.name}'`));
      const { rotation: r, scale: s, translation: t } = project.original_model_transformation;
      let rotation: Quaternion | undefined;
      if (r) {
        rotation = new Quaternion().fromArray(r);
      }
      let scale: number | undefined;
      if (s) {
        scale = s[0];
      }
      let translation: Vector3 | undefined;
      if (t) {
        translation = new Vector3().fromArray(t);
      }
      dispatch(accumulateModelTransformation({ translation, rotation, scale }));
    }

    let bootstrapComputed = false;
    if (project.remesh) {
      if (project.remesh.feather_edge) {
        await dispatch(showGlobalFeedback("loading feather-edge...", `Project '${project.name}'`));
        const spline = new SplinePath(
          FeatherEdgePID,
          projectManager.newMark(FeatherEdgePID),
          getState().theModel.model!,
          convertPath3DToPolyline3D(project.remesh.feather_edge),
          true
        );
        dispatch(setFundamentalCurve("featherEdge", spline));
      }
      if (project.remesh.cone_edge) {
        await dispatch(showGlobalFeedback("loading cone-edge...", `Project '${project.name}'`));
        const spline = new SplinePath(
          ConeEdgePID,
          projectManager.newMark(ConeEdgePID),
          getState().theModel.model!,
          convertPath3DToPolyline3D(project.remesh.cone_edge),
          true
        );
        dispatch(setFundamentalCurve("coneEdge", spline));
      }

      await dispatch(showGlobalFeedback("transforming the model...", `Project '${project.name}'`));
      await dispatch(applyModelTransformation());
      dispatch(hideGlobalFeedback());

      let gridResult: GridResult | undefined;
      const { coneEdge, featherEdge } = getState().remeshModel;
      if (coneEdge && featherEdge) {
        try {
          await dispatch(showGlobalFeedback("computing the remeshing grid...", `Project '${project.name}'`));
          gridResult = computeGrid(featherEdge, coneEdge, getState().theModel.tipThroatDistance);
          dispatch(setRemeshingGrid(
            gridResult.coneAndFeatherPaths,
            gridResult.latitudeAndLongitudePaths,
            gridResult.gridLines,
            gridResult.throat,
            gridResult.tip,
            gridResult.featherLength
          ));
          dispatch(setTipThroatDistance(roundToDecimals(gridResult.featherLength / 3, 2)));
          if (project.remesh.throat_to_tip === undefined) {
            project.remesh.throat_to_tip = getState().theModel.tipThroatDistance;
          }
          dispatch(setGridVisibility(false));
        } catch (e) {
          await dispatch(showGlobalFeedback("remeshing grid uncorrect - skipped.", `Project '${project.name}'`));
        }
      }

      if (project.remesh.throat_to_tip !== undefined) {
        dispatch(setTipThroatDistance(project.remesh.throat_to_tip));
      }

      if (gridResult) {
        await dispatch(showGlobalFeedback("remeshing...", `Project '${project.name}'`));
        try {
          const { remeshedLast, bootstrap } = remeshAndFlattenLast(
            getState().flattenModel.dashboard.canvas,
            gridResult.coneAndFeatherPaths,
            gridResult.latitudeAndLongitudePaths,
            getState().theModel.tipThroatDistance
          );
          dispatch(setRemeshedLast(remeshedLast, bootstrap));
          dispatch(setGridVisibility(true));
          bootstrapComputed = true;
        } catch (e) {
          await dispatch(showGlobalFeedback("remeshing failed - skipped.", `Project '${project.name}'`));
        }
      }
    } else {
      await dispatch(showGlobalFeedback("transforming the model...", `Project '${project.name}'`));
      await dispatch(applyModelTransformation());
      dispatch(hideGlobalFeedback());
    }

    if (project.flattening_image_url) {
      const idx = project.flattening_image_url.lastIndexOf("/");
      const imgURL = project.flattening_image_url.substring(idx >= 0 ? idx + 1 : 0);
      await dispatch(showGlobalFeedback(
        `loading flattening image '${imgURL}'`,
        `Project '${project.name}'`)
      );
      const img = document.createElement("img");
      img.src = project.flattening_image_url;
      await dispatch(setFlatteningImage(img));
    }

    if (project.flattening) {
      const throatArray = project.flattening.throat;
      const tipArray = project.flattening.tip;
      if (throatArray || tipArray) {
        await dispatch(showGlobalFeedback("loading throat/tip alignment points...", `Project '${project.name}'`));
        const throat = throatArray ? new Vector3(throatArray[0], throatArray[1], 0) : undefined;
        const tip = tipArray ? new Vector3(tipArray[0], tipArray[1], 0) : undefined;
        dispatch(setFlatteningAlignmentPoints(undefined, tip));
        if (tip && project.flattening.rotation_angle) {
          dispatch(setFlatteningImageOrientation(project.flattening.rotation_angle));
        }
        if (throat) {
          const m = firstOf(getState().flattenModel.dashboard.graphics.render3D())!.matrix;
          throat.applyMatrix4(m);
        }
        dispatch(setFlatteningAlignmentPoints(throat, tip));
        if (bootstrapComputed && throat && tip) {
          dispatch(changeFlatteningMode(FlattenMode.draw));
        }
      }
      const canvas = getState().flattenModel.dashboard.canvas;
      if (project.flattening.back && project.flattening.back.length > 0) {
        await dispatch(
          showGlobalFeedback("loading flattening back middle-edge...", `Project '${project.name}'`)
        );
        const spline = new SplinePath(
          BackMiddleEdgePID,
          projectManager.newMark(BackMiddleEdgePID),
          canvas,
          convertPath2DToPolyline3D(project.flattening.back),
          false
        );
        dispatch(
          setFlatteningCurve("back", spline)
        );
      }
      if (project.flattening.front && project.flattening.front.length > 0) {
        await dispatch(
          showGlobalFeedback("loading flattening front middle-edge...", `Project '${project.name}'`)
        );
        const spline = new SplinePath(
          FrontMiddleEdgePID,
          projectManager.newMark(FrontMiddleEdgePID),
          canvas,
          convertPath2DToPolyline3D(project.flattening.front),
          false
        );
        dispatch(
          setFlatteningCurve("front", spline)
        );
      }
      if (project.flattening.cone && project.flattening.cone.length > 0) {
        await dispatch(
          showGlobalFeedback("loading flattening cone-edge...", `Project '${project.name}'`)
        );
        const spline = new SplinePath(
          ConeEdgePID,
          projectManager.newMark(ConeEdgePID),
          canvas,
          convertPath2DToPolyline3D(project.flattening.cone),
          false
        );
        dispatch(
          setFlatteningCurve("cone", spline)
        );
      }
      if (project.flattening.exterior && project.flattening.exterior.length > 0) {
        await dispatch(
          showGlobalFeedback("loading flattening exterior feather-edge...", `Project '${project.name}'`)
        );
        const spline = new SplinePath(
          FeatherEdgeExteriorPID,
          projectManager.newMark(FeatherEdgeExteriorPID),
          canvas,
          convertPath2DToPolyline3D(project.flattening.exterior),
          false
        );
        dispatch(
          setFlatteningCurve("exterior", spline)
        );
      }
      if (project.flattening.interior && project.flattening.interior.length > 0) {
        await dispatch(
          showGlobalFeedback("loading flattening interior feather-edge...", `Project '${project.name}'`)
        );
        const spline = new SplinePath(
          FeatherEdgeInteriorPID,
          projectManager.newMark(FeatherEdgeInteriorPID),
          canvas,
          convertPath2DToPolyline3D(project.flattening.interior),
          false
        );
        dispatch(
          setFlatteningCurve("interior", spline)
        );
      }
    }

    dispatch(hideGlobalFeedback());

    dispatch(setProject({ ...project, loading: "complete" }));
  };
}
