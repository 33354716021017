import { CameraOrientation, makeView } from "cadius-cadlib";
import { CadiusScene, IView, ReactiveCadiusScene, ReactiveView } from "cadius-components";

/**
 * Create a `IView` with an empty scene, a view oriented as of `orientation` and the given `id`.
 * @export
 * @param {number} id The id of the IView.
 * @param {CameraOrientation} orientation The orientation for the view.
 * @returns {IView}
 */
export function makeEmptyIView(id: number, orientation: CameraOrientation): IView {
  const cadView = new ReactiveView(makeView(orientation));
  const scene = new ReactiveCadiusScene(new CadiusScene());
  return { id, cadView, scene };
}
