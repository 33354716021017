import { CameraControllerInteractor, Interactor, IRTS, RotoTranslatorInteractor } from "cadius-components";

import { cameraControllerAlignModelStore, rotoTranslatorStore } from "../actions/align-model";
import { FormsAppControl, } from "../controls";
import { FlatteningPointsInteractor } from "../interactors/flattening-point";
import { FlattenMode, IApplicationState } from "../reducers/interfaces";
import { cameraControllerFlattenModelStore, flatteningPointStore } from "./flatten-model";
import { resetInteractors } from "./interactor";
import { CadiusDispatch, CadiusThunkAction } from "./interfaces";
import { cameraControllerRemeshModelStore } from "./remesh-model";
import { disableAllInteractorStackSources } from "./render";
import { ROUTE_ALIGN_MODEL, ROUTE_FLATTEN_MODEL, ROUTE_REMESH_MODEL } from "./routes-types";

/**
 * Signal that the app is entering the /align_model route.
 *
 * This async action creator is called in the render method of a
 * react-router-dom Route component. Since react render methods should be a pure
 * function of props and state, we can't dispatch any action immediately, so we
 * schedule it for "later" with a 0ms timeout.
 */
export const enterAlignModelSection = (): CadiusThunkAction<void> => {
  return async (dispatch: CadiusDispatch): Promise<void> => {

    const cameraControl = new FormsAppControl(cameraControllerAlignModelStore, dispatch);

    const rtsControl = new FormsAppControl<IRTS>(rotoTranslatorStore, dispatch);

    const fn = () => {
      dispatch(disableAllInteractorStackSources());
      dispatch(resetInteractors([
        new CameraControllerInteractor("CameraControllerAlignModel", cameraControl),
        new RotoTranslatorInteractor(rtsControl)
      ]));
      dispatch({ type: ROUTE_ALIGN_MODEL });
    };
    setTimeout(fn, 0);
  }
}

/**
 * Signal that the app is entering the /render_model route.
 */
export const enterRemeshModelSection = (): CadiusThunkAction<void> => {
  return async (dispatch: CadiusDispatch, getState: () => IApplicationState): Promise<void> => {
    // The user may have just refreshed the page thus the last is not available yet
    if (!getState().theModel.model) {
      return;
    }

    const control = new FormsAppControl(cameraControllerRemeshModelStore, dispatch);

    const fn = () => {
      dispatch(disableAllInteractorStackSources());
      dispatch(resetInteractors([new CameraControllerInteractor("CameraControllerRemeshModel", control)]));
      dispatch({ type: ROUTE_REMESH_MODEL });
    };
    setTimeout(fn, 0);
  }
}

/**
 * Signal that the app is entering the /flatten_model route.
 */
export const enterFlattenModelSection = (): CadiusThunkAction<void> => {
  return async (dispatch: CadiusDispatch, getState: () => IApplicationState): Promise<void> => {

    const cameraControl = new FormsAppControl(cameraControllerFlattenModelStore, dispatch);
    const flattenPointControl = new FormsAppControl(flatteningPointStore, dispatch);


    const fn = () => {
      const interactors: Interactor[] = [new CameraControllerInteractor("CameraControllerFlattenModel", cameraControl)];
      dispatch(disableAllInteractorStackSources());
      if (getState().flattenModel.flatImage && getState().flattenModel.mode === FlattenMode.align) {
        interactors.push(new FlatteningPointsInteractor(
          getState().flattenModel.dashboard.canvas,
          flattenPointControl,
          getState().flattenModel.alignmentPoints.throat,
          getState().flattenModel.alignmentPoints.tip
        ));
      }
      dispatch(resetInteractors(interactors));
      dispatch({ type: ROUTE_FLATTEN_MODEL });
    };
    setTimeout(fn, 0);
  }
}
