import { MaterialDB } from "cadius-backend";
import { CameraOrientation } from "cadius-cadlib";
import {
  Decorations,
  LastSource,
  LightsSource,
  SymmetryPlane,
} from "cadius-components";
import { Last } from "cadius-db";

export interface SourcesFromModelResult {
  lastSource: LastSource;
  decorations: Decorations;
  lights: LightsSource;
  symmetryPlane?: SymmetryPlane;
}

export const sourcesFromModel = (last: Last, orientation: CameraOrientation, textureUrl?: string): SourcesFromModelResult => {
  const res: SourcesFromModelResult = {
    decorations: new Decorations(orientation),
    lastSource: new LastSource(last, new MaterialDB()),
    lights: new LightsSource(),
  }
  if (orientation === CameraOrientation.side) {
    return res;
  } else {
    if (textureUrl) {
      res.symmetryPlane = new SymmetryPlane(800, 800, textureUrl);
    } else {
      res.symmetryPlane = new SymmetryPlane();
    }
    return res;
  }
};
