import { CameraOrientation, Size } from "cadius-cadlib";
import {
  CadiusSceneSource,
  CurveSource,
  IView,
  PointSource,
  ReactiveView,
} from "cadius-components";
import { ConeAndFeatherPaths, LatitudeAndLongitudePaths } from "cadius-db";

import { IAction } from "../actions/interfaces";
import { INITIAL_MODEL_SET } from "../actions/load-model";
import {
  CurveName,
  REMESH_SET_CONE_EDGE,
  REMESH_SET_CURVE_VISIBILITY,
  REMESH_SET_FEATHER_EDGE,
  REMESH_SET_GRID_VISIBILITY,
  RESET_REMESHED_LAST_AND_FLATTENING,
  SET_REMESHING_GRID,
  SET_TIP_THROAT_DISTANCE,
} from "../actions/remesh-model";
import { DISABLE_ALL_INTERACTOR_STACK_SOURCE, SCENE_SOURCE_REFRESH } from "../actions/render";
import { ROUTE_REMESH_MODEL } from "../actions/routes-types";
import { SET_CAMERA_SIZE } from "../actions/ui";
import { remeshingPalette } from "../palettes";
import { makeEmptyIView } from "../scene";
import { IApplicationState, IRemeshModelState, RemeshModelSources } from "./interfaces";
import { sourcesFromModel } from "./utils";

export const reducer = (state: IApplicationState, action: IAction): IRemeshModelState => {
  switch (action.type) {
    case DISABLE_ALL_INTERACTOR_STACK_SOURCE: {
      if (state.remeshModel.sources) {
        return {
          ...state.remeshModel,
          sources: {
            ...state.remeshModel.sources,
            interactors: undefined,
          },
        };
      }
      return state.remeshModel;
    }

    case INITIAL_MODEL_SET: {
      const newState = {
        ...state,
        remeshModel: {
          ...state.remeshModel,
          sources: addModelToScene(state).sources,
        },
      };
      newState.remeshModel.view = updateView(newState);

      return newState.remeshModel;
    }

    case SCENE_SOURCE_REFRESH:
      return {
        ...state.remeshModel,
        view: updateView(state),
      };

    case REMESH_SET_CONE_EDGE: {
      const coneEdge = action.payload.spline;
      let coneSource: CurveSource | undefined;
      let coneAndFeatherPaths: ConeAndFeatherPaths | undefined;
      let latitudeAndLongitudePaths: LatitudeAndLongitudePaths | undefined;
      if (!coneEdge || state.remeshModel.coneEdge !== coneEdge) {
        if (coneEdge) {
          const copts = { params: { color: remeshingPalette.get("cone")!, linewidth: 4 } };
          coneSource = new CurveSource(coneEdge, copts);
        } else {
          coneSource = undefined;
        }
        coneAndFeatherPaths = undefined;
        latitudeAndLongitudePaths = undefined;
      } else {
        // this is the case when the new coneEdge is identical to the previous one. There is no need to check for the
        // coneSource to be defined (there is still the source for the old coneEdge)
        coneSource = state.remeshModel.sources!.coneEdge;
        coneSource!.visible = true;
        coneAndFeatherPaths = state.remeshModel.coneAndFeatherPaths;
        latitudeAndLongitudePaths = state.remeshModel.latitudeAndLongitudePaths;
      }

      const newState = {
        ...state,
        remeshModel: {
          ...state.remeshModel,
          coneAndFeatherPaths,
          coneEdge,
          latitudeAndLongitudePaths,
          sources: {
            ...state.remeshModel.sources!,
            coneEdge: coneSource,
          },
        },
      };
      newState.remeshModel.view = updateView(newState);
      return newState.remeshModel;
    }

    case REMESH_SET_FEATHER_EDGE: {
      const featherEdge = action.payload.spline;
      let featherSource: CurveSource | undefined;
      let coneAndFeatherPaths: ConeAndFeatherPaths | undefined;
      let latitudeAndLongitudePaths: LatitudeAndLongitudePaths | undefined;
      if (!featherEdge || state.remeshModel.featherEdge !== featherEdge) {
        if (featherEdge) {
          const copts = { params: { color: remeshingPalette.get("feather")!, linewidth: 4 } };
          featherSource = new CurveSource(featherEdge, copts);
        } else {
          featherSource = undefined;
        }
        coneAndFeatherPaths = undefined;
        latitudeAndLongitudePaths = undefined;
      } else {
        // this is the case when the new featherEdge is identical to the previous one. There is no need to check for the
        // featherSource to be defined (there is still the source for the old featherEdge)
        featherSource = state.remeshModel.sources!.featherEdge;
        featherSource!.visible = true;
        coneAndFeatherPaths = state.remeshModel.coneAndFeatherPaths;
        latitudeAndLongitudePaths = state.remeshModel.latitudeAndLongitudePaths;
      }

      const newState = {
        ...state,
        remeshModel: {
          ...state.remeshModel,
          coneAndFeatherPaths,
          featherEdge,
          latitudeAndLongitudePaths,
          sources: {
            ...state.remeshModel.sources!,
            featherEdge: featherSource,
          },
        },
      };
      newState.remeshModel.view = updateView(newState);
      return newState.remeshModel;
    }

    case REMESH_SET_CURVE_VISIBILITY: {
      const curve = action.payload.curve as CurveName;
      if (!state.remeshModel.sources || !state.remeshModel.sources[curve]) {
        return state.remeshModel;
      }

      const visible = action.payload.visible;
      const newState = {
        ...state,
        remeshModel: {
          ...state.remeshModel,
        },
      };
      newState.remeshModel.sources![curve as CurveName]!.visible = visible;
      newState.remeshModel.view = updateView(newState);
      return newState.remeshModel;
    }

    case RESET_REMESHED_LAST_AND_FLATTENING: {
      const newState = {
        ...state,
        remeshModel: {
          ...state.remeshModel,
          coneAndFeatherPaths: undefined,
          latitudeAndLongitudePaths: undefined,
          sources: {
            ...state.remeshModel.sources!,
            gridLines: [],
            throat: undefined,
            tip: undefined,
          },
        },
      };
      return {
        ...newState.remeshModel,
        view: updateView(newState),
      };
    }

    case REMESH_SET_GRID_VISIBILITY: {
      const { visible } = action.payload;
      state.remeshModel.sources!.gridLines.forEach((c) => c.visible = visible);
      // There are no guarantees the throat point is present when we hide the grid. This action is dispatched even when
      // the grid has not been computed yet. (e.g. when we start editing a fundamental curve and the grid has not been
      // computed yet).
      if (state.remeshModel.sources!.throat) {
        state.remeshModel.sources!.throat.visible = visible;
      }
      if (state.remeshModel.sources!.tip) {
        state.remeshModel.sources!.tip.visible = visible;
      }
      return { ...state.remeshModel };
    }

    case SET_CAMERA_SIZE: {
      let cadView = action.payload!.cadView as ReactiveView;
      const view = state.remeshModel.view;
      if (cadView !== view.cadView) {
        return state.remeshModel;
      }
      const size = action.payload as Size;
      cadView = cadView.resize(size);

      return {
        ...state.remeshModel,
        view: {
          ...view,
          cadView,
        },
      };
    }

    case SET_REMESHING_GRID: {
      const coneAndFeatherPaths: ConeAndFeatherPaths = action.payload!.coneAndFeatherPaths;
      const latitudeAndLongitudePaths: LatitudeAndLongitudePaths = action.payload!.latitudeAndLongitudePaths;
      const gridLines: CurveSource[] = action.payload!.gridLines;
      const throat: PointSource = action.payload!.throat;
      const tip: PointSource = action.payload!.tip;

      gridLines.forEach((c) => c.visible = true);
      throat!.visible = true;
      tip!.visible = true;

      const newState = {
        ...state,
        remeshModel: {
          ...state.remeshModel,
          coneAndFeatherPaths,
          latitudeAndLongitudePaths,
          sources: {
            ...state.remeshModel.sources!,
            gridLines,
            throat,
            tip,
          },
        }
      };
      newState.remeshModel.view = updateView(newState);
      return newState.remeshModel;
    }

    case ROUTE_REMESH_MODEL: {
      if (!state.remeshModel.sources) {
        throw new Error("ASSERT: no sources for remeshing phase");
      }

      return {
        ...state.remeshModel,
        sources: {
          ...state.remeshModel.sources,
          interactors: state.interactorSources,
        },
      };
    }

    case SET_TIP_THROAT_DISTANCE: {
      const { tipThroatDistance } = action.payload;
      const remeshModel = state.remeshModel;
      if (remeshModel.sources && remeshModel.sources.throat && remeshModel.latitudeAndLongitudePaths) {
        const frontGeometry = remeshModel.latitudeAndLongitudePaths.frontMiddleEdge.geometry();
        remeshModel.sources.throat.position = frontGeometry.point(
          frontGeometry.parameterFromLength(Math.max(frontGeometry.length() - tipThroatDistance, 0))
        );
        const newState = {
          ...state,
          remeshModel,
        };
        return {
          ...newState.remeshModel,
          view: updateView(newState),
        };
      }
      return remeshModel;
    }

    default:
      return state.remeshModel;
  }
};

export const initialState = (): IRemeshModelState => {
  return {
    sources: undefined,
    view: makeEmptyIView(0, CameraOrientation.perspective),
  };
};

/**
 * @brief transform the sources to an array so that the result can be passed to `CadiusScene.update()`.
 *
 * @param {RemeshModelSources} sources
 * @returns {CadiusSceneSource[]}
 */
export function remeshSourcesToArray(sources: RemeshModelSources): CadiusSceneSource[] {
  const res = [
    sources.decorations,
    sources.last,
    sources.lights,
  ];
  if (sources.symmetryPlane) {
    res.push(sources.symmetryPlane);
  }
  if (sources.featherEdge) {
    res.push(sources.featherEdge);
  }
  if (sources.coneEdge) {
    res.push(sources.coneEdge);
  }
  if (sources.interactors) {
    res.push(sources.interactors);
  }
  res.push(...sources.gridLines);
  if (sources.throat) {
    res.push(sources.throat);
  }
  if (sources.tip) {
    res.push(sources.tip);
  }
  return res;
}

export const updateView = (state: IApplicationState): IView => {
  if (!state.remeshModel.sources) {
    return state.remeshModel.view;
  }

  return {
    ...state.remeshModel.view,
    scene: state.remeshModel.view.scene.update(remeshSourcesToArray(state.remeshModel.sources), state.ui.ctx),
  }
};

const addModelToScene = (state: IApplicationState) => {
  const fundamentalSources = sourcesFromModel(state.theModel.model!, CameraOrientation.perspective);
  if (!fundamentalSources.symmetryPlane) {
    throw new Error("ASSERT: symmetry plane is undefined when creating remesh-model's scene");
  }

  let coneEdge: CurveSource | undefined;
  let featherEdge: CurveSource | undefined;
  if (state.remeshModel.coneEdge) {
    const copts = { params: { color: remeshingPalette.get("cone")!, linewidth: 4 } };
    coneEdge = new CurveSource(state.remeshModel.coneEdge, copts);
  }

  if (state.remeshModel.featherEdge) {
    const copts = { params: { color: remeshingPalette.get("feather")!, linewidth: 4 } };
    featherEdge = new CurveSource(state.remeshModel.featherEdge, copts);
  }
  const sources: RemeshModelSources = {
    ...state.remeshModel.sources,
    coneEdge,
    decorations: fundamentalSources.decorations,
    featherEdge,
    gridLines: [],
    last: fundamentalSources.lastSource,
    lights: fundamentalSources.lights,
    symmetryPlane: fundamentalSources.symmetryPlane,
  };

  const scene = state.remeshModel.view.scene;
  scene.update(remeshSourcesToArray(sources), state.ui.ctx);

  return {
    scene,
    sources,
  };
};
