import { AppControl } from "cadius-components";
import { IProjectManager } from "cadius-db";

import { IApplicationState } from "../reducers/interfaces";

export type StoreSignature<T extends NonNullable<Record<string, any>>> = (
  state: IApplicationState,
  value: T,
  dispatch: (a: any) => void
) => IApplicationState;

/**
 * @brief The implementation of the interactor controls for this application.
 *
 * This class provides an easy way to create interactor specific controls for this application by sharing the same
 * `refresh` callback.
 *
 * On the other hand the actual definition for the `store()` callback is interactor specific and that's why it needs to
 * be provided while creating the control instance.
 *
 * @template T The type of the parameter for the `store` method.
 */
export class FormsAppControl<T extends NonNullable<Record<string, any>>> implements AppControl<T, IApplicationState> {
  /**
   * @brief A copy of the application state that can be accessed by this class' methods.
   *
   * This class' method may need to alter the application state directly, without dispatching events or returning a
   * value. This is the case for the camera controller that never completes, and so, it never returns a value. The
   * camera controller can not dispatch an action to update the camera because the application may slow down. Instead,
   * the camera controller updates the camera by changing the state directly.
   *
   * Since the `store` does not return any value, it needs a place to store the update application state. This variable
   * is the following static attribute that is set by the reducer with the most recent state and may be accessed by the
   * callback passed to this class constructor.
   *
   * Once the handling of an event has completed, the reducer updates the application state with the one stored in the
   * this attribute.
   *
   * Note: Why dispatching actions is slow compared to changing the state directly?
   *
   * Take again the case of the camera controller: since `handleEvent` is called by the reducer responsable of handling
   * events, the only actions the controller's `store` can dispatch are the asynchronous ones that introduce a delay
   * between the call to the action creator and its effects. In case of the camera controller, dispatching an async
   * action to update the camera delays the update to the next frame and introduces a latency that is unacceptable for
   * user interactions.
   */
  public static state: IApplicationState;

  /**
   * @brief A flag that indicates whether the application needs to update its views.
   *
   * This is similar to the previus `state` attribute: the `refresh` method update this value to ask the application to
   * refresh its views. Once the handling of an event completes, the reducer checks `refreshNeeded` and updates the
   * views accordingly.
   *
   */
  public static refreshNeeded = false;


  protected _dispatch: (action: any) => void;

  /**
   * @brief The reference to the function called by the `store` method.
   */
  protected _store: StoreSignature<T>;

  /**
   * @brief A convenient method to reset both `refreshNeeded` and `state` before starting the handling of an event.
   *
   * @param state The most recent application state to be copied in this class `state` attribute.
   */
  constructor(cb: StoreSignature<T>, dispatch: (a: any) => void) {
    this._store = cb;
    this._dispatch = dispatch;
  }

  /**
   * @brief Returns the current project manager that's stored in the state.
   *
   * It is used by interactors that need to create new entities.
   *
   * @return {IProjectManager} The current project manager.
   * @memberof FormsAppControl
   */
  public projectManager(): IProjectManager {
    return FormsAppControl.state.projectManager;
  }

  /**
   * @brief Ask the application to update its view.
   *
   * This just set `refreshNeeded`. The reducer must then check `refreshNeeded` and perform all the operation to
   * actually update its views.
   */
  public refresh() {
    FormsAppControl.refreshNeeded = true;
  }

  /**
   * @brief Calls the callback passing the most recent application state and `value` as argument. This class `state`
   * attribute is update with the result of the callback.
   *
   * @param value{T} The second parameter passed to the callback given to this class during construction.
   */
  public store(v: T): IApplicationState {
    FormsAppControl.state = this._store(FormsAppControl.state, v, this._dispatch);
    return FormsAppControl.state;
  }
}
