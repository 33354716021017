import { Last } from "cadius-db";
import { ActionCreator } from "redux";
import { IAction } from "./interfaces";

const PREFIX = "[step-0] OPEN MODEL";

/**
 * Dispatched when the model is opened successfully. The payload contains the
 * model itself.
 */
export const INITIAL_MODEL_SET = `${PREFIX} initial-model-set`;

/**
 * Dispatched when the model cannot be opened. The payload contains the reason
 * of the failure.
 */
export const INITIAL_MODEL_FAIL = `${PREFIX} initial-model-fail`;

export const setModel: ActionCreator<IAction> = (last: Last) => {
  return { payload: { model: last }, type: INITIAL_MODEL_SET };
};
