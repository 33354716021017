import {
  CurveSource,
  CurveStoreValue,
  DEFAULT_SNAP_RADIUS_PX,
  EditCurveInteractor,
  IView,
  NewCurveInteractor,
  PointSource,
} from "cadius-components";
import { ConeAndFeatherPaths, LatitudeAndLongitudePaths, SplinePath } from "cadius-db";
import { CppRemeshedLast } from "cadius-geo3d";

import { FormsAppControl, StoreSignature } from "../controls";
import { remeshingPalette } from "../palettes";
import { IApplicationState } from "../reducers/interfaces";
import { FlattenCurves } from "../reducers/support/flatten";
import { startInteractor } from "./interactor";
import { CadiusDispatch, CadiusThunkAction, IAction } from "./interfaces";
import { sceneSourceRefresh } from "./render";
import { saveProjectOnBackend } from "./save-project";

/**
 * @brief The callback used by the CameraController interactor when acting on the view of the remesh phase.
 *
 * This callback takes the view that is the result the camera controller and stores it in the relative view of the
 * remesh phase.
 *
 * @param state The application state;
 * @param newView The updated view.
 */
export const cameraControllerRemeshModelStore: StoreSignature<IView> = (
  state: IApplicationState,
  newView: IView
): IApplicationState => {
  return {
    ...state,
    remeshModel: {
      ...state.remeshModel,
      view: newView,
    },
  };
};

/**
 * @brief Possible values the remeshing curve identifiers.
 */
export type CurveName = "featherEdge" | "coneEdge";

/**
 * @brief The callback used by both `NewCurveInteractor` and `EditCurveInteractor` to store the new fundamental curve
 * (either the feather edge or the cone edge) in the application state. These curves have to be closed.
 *
 * When a new curve is set, any existing remeshing performed so far is invalidated, since a fundamental curve has just
 * been modified.
 *
 * @param state The application state;
 * @param value The object containing a curve identifier and a new value for the new spline returned by the interactor.
 * @param dispatch a callback to dispatch actions to the application state.
 * The identifier is one of `featherEdge` or `coneEdge`.
 */
export const storeRemeshCurveCallback: StoreSignature<CurveStoreValue> = (
  state: IApplicationState,
  value: CurveStoreValue,
  dispatch: CadiusDispatch
) => {
  if (value.identifier !== "featherEdge" && value.identifier !== "coneEdge") {
    throw new Error(`ASSERT: ${value.identifier} is not a valid remeshing curve name`);
  }
  const identifier = value.identifier as CurveName;
  const originalSpline = state.remeshModel[identifier as CurveName];
  // The fundamental curves drawn during the remeshing phase have to be closed.
  let spline = value.spline;
  if (!spline.isLoop) {
    spline = spline.clone({ isLoop: true });
  }

  if (!spline || originalSpline === spline || spline.controlPoints.length < 2) {
    setTimeout(dispatch, 0, setCurveVisibility(identifier as CurveName, true));
    return state;
  }

  setTimeout(() => {
    dispatch(setFundamentalCurve(identifier as CurveName, spline));
    dispatch(resetRemeshedLastAndFlattening(state.theModel.bootstrap));
    dispatch(saveProjectOnBackend());
  }, 0);

  return state;
};

const PREFIX = "[step-2] REMESH MODEL";

/**
 * Dispatched when a fundamental curve is changed or deleted.
 *
 * The payload is:
 * ```ts
 * {
 *   newCurve: SplinePath
 * }
 * ```
 * Where `newCurve` is the new fundamental curve to set.
 */
export const REMESH_SET_FEATHER_EDGE = `${PREFIX} SET_FEATHER_EDGE`;
export const REMESH_SET_CONE_EDGE = `${PREFIX} SET_CONE_EDGE`;

/**
 * Dispatched to change visibility for a fundamental curve.
 *
 * The payload is:
 * ```ts
 * {
 *   curve: CurveName,
 *   visible: boolean,
 * },
 * ```
 * where `curve` is the remeshing curve whose visibility has to change and `visible` its new visibility value.
 */
export const REMESH_SET_CURVE_VISIBILITY = `${PREFIX} REMESH_SET_CURVE_VISIBILITY`;

/**
 * Dispatched to change the remeshing grid visibility.
 *
 * The payload is:
 * ```ts
 * {
 *   visible: boolean,
 * },
 * ```
 * where `visible` is the new visibility value for the remeshing grid.
 */
export const REMESH_SET_GRID_VISIBILITY = `${PREFIX} REMESH_SET_GRID_VISIBILITY`;

/**
 * Dispatched once the remeshing curves have been added to the state.
 */
export const SET_REMESHING_GRID = `${PREFIX} SET_REMESHING_GRID`;

/**
 * Dispatched when we need to the remeshed last.
 *
 * The payload is
 * ```ts
 * {
 *   remeshedLast: CppRemeshedLast,
 *   bootstrap: FlattenCurves,
 *   oldBootstrap?: FlattenCurves,
 * }
 * ```
 * where `remeshedLast` is the last that has just been remeshed, `bootstrap` is the set of new automatically computed
 * flattening curves, and `oldBootstrap` is the optional bootstrap curves previously computed.
 */
export const SET_REMESHED_LAST = `${PREFIX} SET_REMESHED_LAST`;

/**
 * Dispatched when an existing remeshing needs to be invalidated. This is the case when the user performs some changes
 * in the import pipeline (i.e. he changes the last alignment), and the remeshing computed previously is no more
 * correct.
 *
 * The actions of this kind carry the old bootstrap curve with them, so that the reducer can check if the user has
 * edited them; if this is not the case and the flattening curves are still the bootstrap ones, the flattening curves
 * are invalidated too.
 *
 * ```ts
 * {
 *   oldBootstrap: FlattenCurves,
 * }
 * ```
 */
export const RESET_REMESHED_LAST_AND_FLATTENING = `${PREFIX} RESET_REMESHED_LAST_AND_FLATTENING`;

/**
 * @brief Dispatched every time a user provides a new value for the distance from the throat to the tip point.
 *
 * The payload is
 * ```ts
 * {
 *  tipThroatDistance,
 * }
 * ```
 * where `tipThroatDistance` is the distance from the `tip` to the `throat` point.
 */
export const SET_TIP_THROAT_DISTANCE = `${PREFIX} SET_TIP_THROAT_DISTANCE`;

/**
 * @brief Sets the distance (in mm) between the throat point (calzata) and the tip point (punta).
 */
export function setTipThroatDistance(tipThroatDistance: number): IAction {
  return {
    payload: {
      tipThroatDistance,
    },
    type: SET_TIP_THROAT_DISTANCE,
  };
}

export function setCurveVisibility(curve: CurveName, visible: boolean): IAction {
  return {
    payload: { curve, visible },
    type: REMESH_SET_CURVE_VISIBILITY,
  };
}

export function setGridVisibility(visible: boolean): IAction {
  return {
    payload: { visible },
    type: REMESH_SET_GRID_VISIBILITY,
  };
}

/**
 * Dispatched when the user changes or deletes a fundamental curve (e.g. Feather Edge).
 */
export const setFundamentalCurve = (curve: CurveName, spline?: SplinePath): IAction => {
  if (curve === "featherEdge") {
    return { payload: { spline }, type: REMESH_SET_FEATHER_EDGE };
  }
  return { payload: { spline }, type: REMESH_SET_CONE_EDGE };
};

/**
 * @brief Create a thunk action that starts the interactor that will add the given fundamental entity.
 *
 * @param curve{CurveName} either `featherEdge` or `coneEdge`.
 */
export const startDrawingFundamentalCurve = (curve: CurveName): CadiusThunkAction<void> => {
  const colorKey = curve === "coneEdge" ? "cone" : "feather";
  const color = remeshingPalette.get(colorKey);
  if (!color) {
    throw new Error("ASSERT: unable to find color for " + curve);
  }

  return async (dispatch: CadiusDispatch, getState: () => IApplicationState) => {
    const { theModel } = getState();
    if (!theModel.model) {
      throw new Error("ASSERT: A model must be set in `theModel` state");
    }
    dispatch(setCurveVisibility(curve, false));

    const control = new FormsAppControl(storeRemeshCurveCallback, dispatch);

    dispatch(startInteractor(new NewCurveInteractor(
      theModel.model,
      curve,
      control,
      DEFAULT_SNAP_RADIUS_PX,
      color,
      true
    )));
  }
};

/**
 * @brief Create a thunk action that starts the interactor that will edit the given fundamental entity.
 *
 * @param curve{CurveName} either `featherEdge` or `coneEdge`.
 */
export const editFundamentalCurve = (curve: CurveName): CadiusThunkAction<void> => {
  const colorKey = curve === "coneEdge" ? "cone" : "feather";
  const color = remeshingPalette.get(colorKey)!;

  return async (dispatch: CadiusDispatch, getState: () => IApplicationState) => {
    const { remeshModel, theModel } = getState();
    if (!theModel.model) {
      throw new Error("ASSERT: A model must be set in `remeshModel` state");
    }

    const spline = remeshModel[curve];
    if (!spline) {
      // The line to edit may not exist yet. Do not do anything if this is the case.
      return;
    }

    dispatch(setCurveVisibility(curve, false));
    const control = new FormsAppControl(storeRemeshCurveCallback, dispatch);
    dispatch(startInteractor(new EditCurveInteractor(
      theModel.model,
      curve,
      spline,
      control,
      DEFAULT_SNAP_RADIUS_PX,
      color,
      undefined,
      undefined,
      true
    )));
    dispatch(sceneSourceRefresh());
  }
};

export const setRemeshingGrid = (
  coneAndFeatherPaths: ConeAndFeatherPaths,
  latitudeAndLongitudePaths: LatitudeAndLongitudePaths,
  gridLines: CurveSource[],
  throat: PointSource,
  tip: PointSource,
  featherLength: number
): IAction => {
  return {
    payload: { coneAndFeatherPaths, featherLength, gridLines, latitudeAndLongitudePaths, throat, tip },
    type: SET_REMESHING_GRID,
  };
};

/**
 * @brief Set the remeshed last and its flattening curves in the application state.
 *
 * The optional `olbBootstrap` parameter should contain the bootstrap curves for a previously remeshed last. This is
 * used by the reducer to decide whether to reset the flattening curves to the new bootsrap provided, or not.
 *
 * @param remeshedLast The new remeshed last;
 * @param bootstrap The flattening curves for the last just remeshed;
 * @param oldBootstrap The optional flattening curves that have been computed previously.
 */
export const setRemeshedLast = (
  remeshedLast: CppRemeshedLast,
  bootstrap: FlattenCurves,
  oldBootstrap?: FlattenCurves
): IAction => {
  return {
    payload: { bootstrap, oldBootstrap, remeshedLast },
    type: SET_REMESHED_LAST,
  };
};

/**
 * @brief Reset any remeshing performed so far. This invalidates the remeshed last, the remeshing grid, the bootstrap
 * curves and, in case the flattening lines have not been modified by the user yet, it resets the flattening curves too.
 *
 * @param oldBootstrap the existing bootstrap curves. These are used by the flatten
 */
export const resetRemeshedLastAndFlattening = (oldBootstrap?: FlattenCurves): IAction => {
  return {
    payload: { bootstrap: oldBootstrap },
    type: RESET_REMESHED_LAST_AND_FLATTENING,
  };
}
