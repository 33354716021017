import { Path2D, Path3D } from "cadius-backend";
import { Matrix3, Matrix4, Vector2, Vector3 } from "three";

export const convertPolyline3DToPath3D = (() => {
  const p = new Vector3();

  return (points: Vector3[], m?: Matrix4): Path3D => {
    const path3D = new Array<number>();
    for (const cp of points) {
      p.copy(cp);
      if (m) {
        p.applyMatrix4(m);
      }
      path3D.push(p.x, p.y, p.z);
    }
    return path3D;
  };
})();

export const convertPolyline3DToPath2D = (() => {
  const p = new Vector3();

  return (points: Vector3[], m?: Matrix4): Path2D => {
    const path2D = new Array<number>();
    for (const cp of points) {
      p.copy(cp);
      if (m) {
        p.applyMatrix4(m);
      }
      path2D.push(p.x, p.y);
    }
    return path2D;
  };
})();

export const convertPolyline2DToPath2D = (() => {
  const p = new Vector2();

  return (points: Vector2[], m?: Matrix3): Path2D => {
    const path2D = new Array<number>();
    for (const cp of points) {
      p.copy(cp);
      if (m) {
        p.applyMatrix3(m);
      }
      path2D.push(p.x, p.y);
    }
    return path2D;
  };
})();

export function convertPath3DToPolyline3D(
  path: Path3D,
  m?: Matrix4
): Vector3[] {
  const points = new Array<Vector3>();
  for (let i = 0; i < path.length; i += 3) {
    const cp = new Vector3(path[i + 0], path[i + 1], path[i + 2]);
    if (m) {
      cp.applyMatrix4(m);
    }
    points.push(cp);
  }
  return points;
}

export function convertPath2DToPolyline2D(
  path: Path2D,
  m?: Matrix3
): Vector2[] {
  const points = new Array<Vector2>();
  for (let i = 0; i < path.length; i += 2) {
    const cp = new Vector2(path[i + 0], path[i + 1]);
    if (m) {
      cp.applyMatrix3(m);
    }
    points.push(cp);
  }
  return points;
}

export function convertPath2DToPolyline3D(
  path: Path2D,
  m?: Matrix4
): Vector3[] {
  const points = new Array<Vector3>();
  for (let i = 0; i < path.length; i += 2) {
    const cp = new Vector3(path[i + 0], path[i + 1], 0);
    if (m) {
      cp.applyMatrix4(m);
    }
    points.push(cp);
  }
  return points;
}
