/*
 * Actions related to route navigations and transitions.
 */
const PREFIX = "[ROUTE]";

/**
 * Dispatched when the user navigates to the /align_model route.
 */
export const ROUTE_ALIGN_MODEL = `${PREFIX} ALIGN_MODEL`;

/**
 * Dispatched when the user navigates to the /remesh_model route.
 */
export const ROUTE_REMESH_MODEL = `${PREFIX} REMESH_MODEL`;

/**
 * Dispatched when the user navigates to the /flatten_model route.
 */
export const ROUTE_FLATTEN_MODEL = `${PREFIX} FLATTEN_MODEL`;
