import { IAction } from "../actions/interfaces";
import { BackendRemeshingProject } from "../reducers/interfaces";

const PREFIX = "[backend]";

/**
 * Action type dispatched when the logic flow reaches a key moment to send the updated project to the backend.
 * For example, e key moment is when a new feather-edge has been just set during the remeshing phase.
 */
export const SET_PROJECT = `${PREFIX} SET_PROJECT`;

/**
 * Action type dispatched when the user has just set a new flattening image. Such image must be sent to the backend
 * which then returns the URL in its storage. This action regards synchronizing the remeshing project in the current
 * state with such URL.
 */
export const SET_BACKEND_FLATTENING_IMAGE_URL = `${PREFIX} SET_BACKEND_FLATTENING_IMAGE_URL`;

export const SET_BACKEND_REMESHED_LAST_URL = `${PREFIX} SET_BACKEND_REMESHED_LAST_URL`;

export function setProject(project?: BackendRemeshingProject): IAction {
  return {
    payload: project,
    type: SET_PROJECT,
  };
}

/**
 * Action creator to dispatch the action `SET_BACKEND_FLATTENING_IMAGE_URL`. When the user sets a new flattening
 * image and it is sent to the backend, it then returns the final URL in its storage. So we need to dispatch this action
 * to synchronize the remeshing project in the current state.
 * @export
 * @param {string} url The final URL of the image sent to the backend.
 * @returns {IAction}
 */
export function setBackendFlatteningImageURL(url: string): IAction {
  return {
    payload: url,
    type: SET_BACKEND_FLATTENING_IMAGE_URL,
  };
}

export function setBackendRemeshedLastURL(url: string): IAction {
  return {
    payload: url,
    type: SET_BACKEND_REMESHED_LAST_URL,
  };
}
