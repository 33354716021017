import { Cad, Interactor, InteractorStack } from "cadius-components";

import { HANDLE_CAD_EVENT } from "../actions/events";
import { RESET_INTERACTORS, START_INTERACTOR } from "../actions/interactor";
import { IAction } from "../actions/interfaces";
import { FormsAppControl } from "../controls";
import { updateViews as updateAlignViews } from "./align-model";
import { IApplicationState } from "./interfaces";
import { updateView as updateRemeshView } from "./remesh-model";
import { updateView as updateFlattenView } from "./support/flatten";

/**
 * Interactor Stack reducer.
 *
 * The interactor stack reducer manages the creation of new interactors and updates the `interactorStack` state, a piece
 * of application state which is **mutable**.
 *
 * The `interactorStack` state is opaque to redux: the interactor stack reducer takes care of dispatching the Cad events
 * contained in the action payload, and returning the entire application state. The interactor stack reducer needs to to
 * see the entire application state in order to decide how to handle the incoming Cad events.
 */
export const reducer = (
  state: IApplicationState,
  action: IAction,
): IApplicationState => {
  switch (action.type) {
    case HANDLE_CAD_EVENT:
      FormsAppControl.refreshNeeded = false;
      const evt = action.payload.evt as Cad.Event;
      FormsAppControl.state = state;
      state.interactorStack.dispatchEvent(evt);
      state = FormsAppControl.state;
      if (FormsAppControl.refreshNeeded) {
        return updateAllViews(state);
      }
      return state;

    case RESET_INTERACTORS:
      state.interactorStack.reset();
      const interactors = action.payload.interactors as Interactor[];
      for (const i of interactors) {
        state.interactorStack.push(i);
      }
      return { ...state };

    case START_INTERACTOR:
      state.interactorStack.push(action.payload.interactor);
      return { ...state };

    default:
      return state;
  }
};

function updateAllViews(state: IApplicationState): IApplicationState {
  return {
    ...state,
    alignModel: {
      ...state.alignModel,
      views: updateAlignViews(state),
    },
    flattenModel: {
      ...state.flattenModel,
      view: updateFlattenView(state),
    },
    remeshModel: {
      ...state.remeshModel,
      view: updateRemeshView(state),
    },
  };
}

/**
 * The initial state of the `interactorStack` piece of state.
 *
 * Note: this function returns only a portion of the application state, while the interactor stack reducer accepts and
 * returns the entire app state. This characteristic is due to the fact that the interactor stack reducer does not see
 * only a slice of the entire application state (i.e. it's not a slice reducer like the other reducers in this
 * application).
 */
export const initialState = (): InteractorStack => {
  return new InteractorStack();
};
