import { CameraOrientation, Size } from "cadius-cadlib";
import { CadiusSceneSource, ReactiveView } from "cadius-components";
import { Quaternion, Vector3 } from "three";

import { ACCUMULATE_MODEL_TRANSFORMATION } from "../actions/align-model";
import { IAction } from "../actions/interfaces";
import { INITIAL_MODEL_FAIL, INITIAL_MODEL_SET } from "../actions/load-model";
import { SCENE_SOURCE_REFRESH } from "../actions/render";
import { ROUTE_ALIGN_MODEL } from "../actions/routes-types";
import { SET_CAMERA_SIZE } from "../actions/ui";
import { makeEmptyIView } from "../scene";
import {
  AlignModelViews,
  IAlignModelState,
  IAlignModelView,
  IApplicationState,
  ITransform,
} from "./interfaces";
import { sourcesFromModel } from "./utils";

export const reducer = (state: IApplicationState, action: IAction): IAlignModelState => {
  switch (action.type) {

    case INITIAL_MODEL_FAIL:
      return initialState();

    case INITIAL_MODEL_SET:
      if (state.theModel.model) {
        return {
          ...state.alignModel,
          localTransformation: resetTransformation(),
          views: generateViews(state),
        };
      }
      return state.alignModel;

    case ROUTE_ALIGN_MODEL:
      if (!state.theModel.model) {
        return state.alignModel;
      }
      return { ...state.alignModel, views: updateViews(state) };

    case SET_CAMERA_SIZE: {
      const cadView = action.payload!.cadView as ReactiveView;
      const size = action.payload as Size;
      const newViews: IAlignModelView[] = [];
      let changed = false;
      for (const view of state.alignModel.views) {
        if (cadView === view.cadView) {
          newViews.push({
            ...view,
            cadView: cadView.resize(size),
          });
          changed = true;
        } else {
          newViews.push(view);
        }
      }
      if (changed) {
        return {
          ...state.alignModel,
          views: newViews as AlignModelViews,
        };
      }
      return state.alignModel;
    }

    case ACCUMULATE_MODEL_TRANSFORMATION: {
      const deltaTransformations = action.payload;
      const position = deltaTransformations.translation ?
        state.alignModel.localTransformation.position.clone().add(deltaTransformations.translation) :
        state.alignModel.localTransformation.position;

      const rotation = deltaTransformations.rotation ?
        state.alignModel.localTransformation.rotation.clone().premultiply(deltaTransformations.rotation) :
        state.alignModel.localTransformation.rotation;

      const scale = deltaTransformations.scale !== undefined ?
        state.alignModel.localTransformation.scale * deltaTransformations.scale :
        state.alignModel.localTransformation.scale;

      // update the rendered last
      for (const v of state.alignModel.views) {
        if (v.renderedLast) {
          v.renderedLast.position.copy(position);
          v.renderedLast.quaternion.copy(rotation);
          v.renderedLast.scale.setScalar(scale);
          v.renderedLast.updateMatrix();
        }
      }

      return {
        ...state.alignModel,
        localTransformation: {
          position,
          rotation,
          scale,
        },
        views: updateViews(state),
      };
    }

    case SCENE_SOURCE_REFRESH: {
      return {
        ...state.alignModel,
        views: updateViews(state),
      };
    }

    default:
      return state.alignModel;
  }
};

const initialView = (viewId: number, orientation: CameraOrientation): IAlignModelView => {
  return { ...makeEmptyIView(viewId, orientation), renderedLast: undefined };
};

/**
 * View with a camera and a scene with all the sources related to the model.
 */
export const generateViews = (
  state: IApplicationState
): [IAlignModelView, IAlignModelView, IAlignModelView, IAlignModelView] => {
  const model = state.theModel.model;
  const orientations = [
    CameraOrientation.side,
    CameraOrientation.front,
    CameraOrientation.top,
    CameraOrientation.perspective
  ];
  const makeModelView = (view: IAlignModelView, idx: number): IAlignModelView => {
    const sources = model ? sourcesFromModel(model, orientations[idx]) : undefined;
    const sourceArray: CadiusSceneSource[] = sources ? [sources.decorations, sources.lights, sources.lastSource] : [];
    if (sources && sources.symmetryPlane) {
      sourceArray.push(sources.symmetryPlane);
    }
    return {
      ...view,
      cadView: view.cadView.touch(),
      renderedLast: sources?.lastSource.lastMesh(),
      scene: sources ? view.scene.update(sourceArray, state.ui.ctx) : view.scene.touch(state.ui.ctx),
    };
  }
  return state.alignModel.views.map(makeModelView) as AlignModelViews;
};

export const updateViews = (
  state: IApplicationState
): [IAlignModelView, IAlignModelView, IAlignModelView, IAlignModelView] => {
  const makeModelView = (view: IAlignModelView): IAlignModelView => {
    return {
      ...view,
      scene: view.scene.touch(state.ui.ctx)
    };
  }
  return state.alignModel.views.map(makeModelView) as AlignModelViews;
};

export const initialState = (): IAlignModelState => {
  const localTransformation = resetTransformation();
  const views: [IAlignModelView, IAlignModelView, IAlignModelView, IAlignModelView] = [
    initialView(0, CameraOrientation.side),
    initialView(1, CameraOrientation.front),
    initialView(2, CameraOrientation.top),
    initialView(3, CameraOrientation.perspective),
  ];

  return {
    localTransformation,
    views,
  };
}

/**
 * @brief Returns a neutral transformation.
 *
 * @export
 * @returns
 */
function resetTransformation(): ITransform {
  return {
    position: new Vector3(),
    rotation: new Quaternion(),
    scale: 1,
  };
}
