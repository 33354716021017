import { Reducer } from "redux";

import { IAction } from "../actions/interfaces";
import { INITIAL_MODEL_FAIL, INITIAL_MODEL_SET } from "../actions/load-model";
import { IOpenModelState } from "./interfaces";

export const reducer: Reducer<IOpenModelState, IAction> = (
  state: IOpenModelState = initialState(),
  action: IAction
): IOpenModelState => {
  switch (action.type) {
    case INITIAL_MODEL_SET:
      return {
        ...state,
        failureMessage: "",
      };

    case INITIAL_MODEL_FAIL:
      return {
        ...state,
        failureMessage: action.payload.msg,
      };

    default:
      return state;
  }
};

export const initialState = (): IOpenModelState => {
  return { failureMessage: "" };
}
