import { IRTS, IView } from "cadius-components";
import { Matrix4, Vector3 } from "three";

import { StoreSignature } from "../controls";
import { IAlignModelView, IApplicationState } from "../reducers/interfaces";
import { isIdentity } from "../reducers/support/align";
import { makeMatrix } from "../support/align";
import { CadiusDispatch, CadiusThunkAction } from "./interfaces";
import { setModel } from "./load-model";
import { resetRemeshedLastAndFlattening, setFundamentalCurve } from "./remesh-model";
import { saveProjectOnBackend } from "./save-project";

/**
 * @brief The callback used by the CameraController interactor when acting on the views of the align phase.
 *
 * This callback takes the view that is the result the camera controller and stores it in the relative view of the align
 * phase.
 *
 * @param state The application state;
 * @param newView The updated view.
 */
export const cameraControllerAlignModelStore: StoreSignature<IView> = (
  state: IApplicationState,
  newView: IView
): IApplicationState => {
  type Views = [IAlignModelView, IAlignModelView, IAlignModelView, IAlignModelView];
  const views = [...state.alignModel.views] as Views;
  views[newView.id] = newView;

  return {
    ...state,
    alignModel: {
      ...state.alignModel,
      views,
    },
  };
};

/**
 * @brief The callback used by the RotoTranslator when applying `transformation` to the model.
 *
 * @param state The application state;
 * @param transformation The transformation to be applied.
 */
export const rotoTranslatorStore: StoreSignature<IRTS> = (
  state: IApplicationState,
  transformation: IRTS,
  dispatch: (a: any) => void
) => {
  setTimeout(dispatch, 0, accumulateModelTransformation(transformation));

  return state;
};

const PREFIX = "[step-1] ALIGN MODEL";

export const ACCUMULATE_MODEL_TRANSFORMATION = `${PREFIX} accumulate-model-transformation`;

export const accumulateModelTransformation = (
  deltaTransformations: IRTS
) => {
  return {
    payload: deltaTransformations,
    type: ACCUMULATE_MODEL_TRANSFORMATION,
  };
};

/**
 * Dispatched once the aligning phase has completed and the transformation can
 * be applied to the working model.
 */
export const applyModelTransformation = (): CadiusThunkAction<void> => {
  const transformation = new Matrix4();

  return async (dispatch: CadiusDispatch, getState: () => IApplicationState) => {
    const { position, rotation, scale } = getState().alignModel.localTransformation;
    if (isIdentity(position, rotation, scale)) {
      return;
    }

    /*
     * TODO: The entire triangle mesh is transformed; this solution is simpler but prevent us from implementing a fast
     * "undo" for the transformation. The other possible solution is to store the transformation in the working model
     * state, and apply them only at the end of the importing process. In the latter solution the local transformation
     * are the ones applied during the alignment phase while the global transformation are the ones that are stored in
     * the working model once the alignment has completed. The local transformations become global when the user is
     * redirected outside the aligment phase, and vice versa when he is redirected to the alignment phase.
     *
     * Please note that the solution we opted for (transforming the soup as soon as the user exits the alignment
     * phase) never changes the global transformation!
     */
    getState().theModel.model!.transform(position, rotation, scale);
    let { coneEdge, featherEdge } = getState().remeshModel;

    makeMatrix(position, rotation, scale, transformation);

    const transformAndProjectCurve = (points: Vector3[]) => {
      const res: Vector3[] = [];
      const mesh = getState().theModel.model!.mesh;
      for (const r of mesh.nearestLoopPoints(points.map((p) => p.clone().applyMatrix4(transformation)))) {
        if (r) {
          res.push(r[0]);
        }
      }
      return res;
    };

    if (coneEdge) {
      coneEdge = coneEdge.clone({ controlPoints: transformAndProjectCurve(coneEdge.controlPoints) });
      dispatch(setFundamentalCurve("coneEdge", coneEdge));
    }

    if (featherEdge) {
      featherEdge = featherEdge.clone({ controlPoints: transformAndProjectCurve(featherEdge.controlPoints) });
      dispatch(setFundamentalCurve("featherEdge", featherEdge));
    }

    dispatch(saveProjectOnBackend());

    // every phase of the application has to be informed the model has changed.
    dispatch(setModel(getState().theModel.model!));

    dispatch(resetRemeshedLastAndFlattening(getState().theModel.bootstrap));
  };
};
