import { CameraControllerInteractor } from "cadius-components";
import { ProjectionSurface } from "cadius-db";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Vector3 } from "three";

import {
  changeFlatteningMode,
  setFlatteningAlignmentPoints,
} from "../actions/flatten-model";
import { cameraControllerFlattenModelStore, flatteningPointStore } from "../actions/flatten-model";
import { resetInteractors } from "../actions/interactor";
import { CadiusDispatch } from "../actions/interfaces";
import { sceneSourceRefresh } from "../actions/render";
import { saveProjectOnBackend } from "../actions/save-project";
import { FlatteningAlignmentPoints as FlatteningAlignmentPointsImpl } from "../components/FlatteningAlignmentPoints";
import { FormsAppControl } from "../controls";
import { FlatteningPointsInteractor } from "../interactors/flattening-point";
import { FlattenMode, IApplicationState } from "../reducers/interfaces";


interface IStateProps {
  canvas: ProjectionSurface;
  throatPoint?: Vector3;
  tipPoint?: Vector3;
}

function mapStateToProps(state: IApplicationState): IStateProps {
  return {
    canvas: state.flattenModel.dashboard.canvas,
    throatPoint: state.flattenModel.alignmentPoints.throat,
    tipPoint: state.flattenModel.alignmentPoints.tip,
  };
}

interface IDispatchProps {
  changeFlatteningMode: (mode: FlattenMode) => void;
  setFlatteningAlignmentPoints: (projectionSurface: ProjectionSurface, throat?: Vector3, tip?: Vector3) => void;
  sceneSourceRefresh: () => void;
}

function mapDispatchToProps(dispatch: CadiusDispatch) {
  const actionCreators = bindActionCreators(
    {
      changeFlatteningMode,
      sceneSourceRefresh,
    },
    dispatch
  );

  return {
    ...actionCreators,
    setFlatteningAlignmentPoints: (projectionSurface: ProjectionSurface, throat?: Vector3, tip?: Vector3) => {
      dispatch(setFlatteningAlignmentPoints(throat, tip));
      if (!throat && !tip) {
        const cameraControl = new FormsAppControl(cameraControllerFlattenModelStore, dispatch);
        const flattenPointsControl = new FormsAppControl(flatteningPointStore, dispatch);
        dispatch(resetInteractors([
          new CameraControllerInteractor("CameraControllerFlattenModel", cameraControl),
          new FlatteningPointsInteractor(projectionSurface, flattenPointsControl)
        ]));
        dispatch(sceneSourceRefresh());
      }
      dispatch(saveProjectOnBackend());
    }
  };
}

function mergeProps<TOwnProps extends Record<string, unknown>>(
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  ownProps: TOwnProps
) {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    changeFlatteningMode: (mode: FlattenMode) => {
      dispatchProps.changeFlatteningMode(mode);
      dispatchProps.sceneSourceRefresh();
    },
    setFlatteningAlignmentPoints: (throat?: Vector3, tip?: Vector3) => {
      dispatchProps.setFlatteningAlignmentPoints(stateProps.canvas, throat, tip);
    },
  };
}

const enhance = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
);

export const FlatteningAlignmentPoints = enhance(FlatteningAlignmentPointsImpl);
