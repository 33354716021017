import { RemeshingProject } from "cadius-backend";
import { IRTS } from "cadius-components";
import { FundamentalBoundaryPolylines, SplinePath } from "cadius-db";
import { firstOf } from "cadius-stdlib";
import { Matrix4, Quaternion, Vector3 } from "three";

import { ACCUMULATE_MODEL_TRANSFORMATION } from "../actions/align-model";
import { SET_BACKEND_FLATTENING_IMAGE_URL, SET_BACKEND_REMESHED_LAST_URL, SET_PROJECT } from "../actions/backend";
import {
  IMPOSE_FLATTENING_BOUNDARIES,
  SET_FLATTENING_ALIGNMENT_POINTS,
  SET_FLATTENING_CURVE,
  SET_FLATTENING_IMAGE_ORIENTATION,
} from "../actions/flatten-model";
import { IAction } from "../actions/interfaces";
import {
  REMESH_SET_CONE_EDGE,
  REMESH_SET_FEATHER_EDGE,
  SET_REMESHED_LAST,
  SET_TIP_THROAT_DISTANCE,
} from "../actions/remesh-model";
import { makeMatrix } from "../support/align";
import { convertPolyline2DToPath2D, convertPolyline3DToPath2D, convertPolyline3DToPath3D } from "../support/backend";
import { BackendRemeshingProject, IApplicationState } from "./interfaces";
import { isIdentity } from "./support/align";
import { FlatCurvesNames, FlattenCurves } from "./support/flatten";

type backendFlattenCurveNames = "back_middle" | "cone" | "exterior_feather" | "front_middle" | "interior_feather";
const mappedFlattenCurveNames = new Map<FlatCurvesNames, backendFlattenCurveNames>();
mappedFlattenCurveNames.set("back", "back_middle");
mappedFlattenCurveNames.set("cone", "cone");
mappedFlattenCurveNames.set("exterior", "exterior_feather");
mappedFlattenCurveNames.set("front", "front_middle");
mappedFlattenCurveNames.set("interior", "interior_feather");

const matrix = new Matrix4();
const inverseMatrix = new Matrix4();
const tmpTranslation = new Vector3();
const tmpScale = new Vector3();
const tmpRotation = new Quaternion();

export const reducer = (state: IApplicationState, action: IAction): RemeshingProject | undefined => {
  if (action.type === SET_PROJECT) {
    return action.payload;
  }

  const remeshingProject = state.remeshingProject;
  if (!remeshingProject) {
    return remeshingProject;
  }

  switch (action.type) {
    case ACCUMULATE_MODEL_TRANSFORMATION: {
      const { translation, rotation, scale } = action.payload as IRTS;
      if (isIdentity(translation, rotation, scale)) {
        return remeshingProject;
      }
      let newRemeshingProject = remeshingProject;
      if (translation || rotation || scale !== undefined) {
        newRemeshingProject = {
          ...newRemeshingProject,
          original_model_transformation: {
            ...newRemeshingProject.original_model_transformation,
          },
        };
      }
      if (translation) {
        if (newRemeshingProject.original_model_transformation!.translation) {
          tmpTranslation.fromArray(newRemeshingProject.original_model_transformation!.translation).add(translation);
        } else {
          tmpTranslation.copy(translation);
        }
        newRemeshingProject.original_model_transformation!.translation = tmpTranslation.toArray();
      }
      if (rotation) {
        if (newRemeshingProject.original_model_transformation!.rotation) {
          tmpRotation.fromArray(newRemeshingProject.original_model_transformation!.rotation).premultiply(rotation);
        } else {
          tmpRotation.copy(rotation);
        }
        newRemeshingProject.original_model_transformation!.rotation = tmpRotation.toArray();
      }
      if (scale !== undefined) {
        if (newRemeshingProject.original_model_transformation!.scale) {
          tmpScale.fromArray(newRemeshingProject.original_model_transformation!.scale).multiplyScalar(scale);
        } else {
          tmpScale.setScalar(scale);
        }
        newRemeshingProject.original_model_transformation!.scale = tmpScale.toArray();
      }
      return newRemeshingProject;
    }

    case REMESH_SET_FEATHER_EDGE: {
      const spline = action.payload.spline as SplinePath | undefined;
      const newRemeshingProject = { ...remeshingProject };
      if (!newRemeshingProject.remesh) {
        newRemeshingProject.remesh = {};
      }
      if (spline) {
        if (newRemeshingProject.original_model_transformation) {
          if (newRemeshingProject.original_model_transformation.translation) {
            tmpTranslation.fromArray(newRemeshingProject.original_model_transformation.translation);
          } else {
            tmpTranslation.setScalar(0);
          }
          if (newRemeshingProject.original_model_transformation.rotation) {
            tmpRotation.fromArray(newRemeshingProject.original_model_transformation.rotation);
          } else {
            tmpRotation.set(0, 0, 0, 1);
          }
          if (newRemeshingProject.original_model_transformation.scale) {
            tmpScale.fromArray(newRemeshingProject.original_model_transformation.scale);
          } else {
            tmpScale.setScalar(1);
          }
          makeMatrix(tmpTranslation, tmpRotation, tmpScale.x, matrix);
          inverseMatrix.getInverse(matrix);
          newRemeshingProject.remesh.feather_edge = convertPolyline3DToPath3D(spline.controlPoints, inverseMatrix);
        } else {
          newRemeshingProject.remesh.feather_edge = convertPolyline3DToPath3D(spline.controlPoints);
        }
      } else {
        newRemeshingProject.remesh.feather_edge = undefined;
      }
      return newRemeshingProject;
    }

    case REMESH_SET_CONE_EDGE: {
      const spline = action.payload.spline as SplinePath | undefined;
      const newRemeshingProject = { ...remeshingProject };
      if (!newRemeshingProject.remesh) {
        newRemeshingProject.remesh = {};
      }
      if (spline) {
        if (newRemeshingProject.original_model_transformation) {
          if (newRemeshingProject.original_model_transformation.translation) {
            tmpTranslation.fromArray(newRemeshingProject.original_model_transformation.translation);
          } else {
            tmpTranslation.setScalar(0);
          }
          if (newRemeshingProject.original_model_transformation.rotation) {
            tmpRotation.fromArray(newRemeshingProject.original_model_transformation.rotation);
          } else {
            tmpRotation.set(0, 0, 0, 1);
          }
          if (newRemeshingProject.original_model_transformation.scale) {
            tmpScale.fromArray(newRemeshingProject.original_model_transformation.scale);
          } else {
            tmpScale.setScalar(1);
          }
          makeMatrix(tmpTranslation, tmpRotation, tmpScale.x, matrix);
          inverseMatrix.getInverse(matrix);
          newRemeshingProject.remesh.cone_edge = convertPolyline3DToPath3D(spline.controlPoints, inverseMatrix);
        } else {
          newRemeshingProject.remesh.cone_edge = convertPolyline3DToPath3D(spline.controlPoints);
        }
      } else {
        newRemeshingProject.remesh.cone_edge = undefined;
      }
      return newRemeshingProject;
    }

    case SET_REMESHED_LAST: {
      let newRemeshingProject = remeshingProject;
      let curves: FlattenCurves;
      const oldBootstrap: FlattenCurves | undefined = action.payload.oldBootstrap;
      if ((state.flattenModel.curves.areEmpty() && !oldBootstrap) || state.flattenModel.curves === oldBootstrap) {
        curves = action.payload.bootstrap;
        newRemeshingProject = { ...remeshingProject };
        if (!newRemeshingProject.flattening) {
          newRemeshingProject.flattening = {};
        }
        if (curves.back) {
          newRemeshingProject.flattening.back = convertPolyline3DToPath2D(curves.back.spline.controlPoints);
        }
        if (curves.cone) {
          newRemeshingProject.flattening.cone = convertPolyline3DToPath2D(curves.cone.spline.controlPoints);
        }
        if (curves.exterior) {
          newRemeshingProject.flattening.exterior = convertPolyline3DToPath2D(curves.exterior.spline.controlPoints);
        }
        if (curves.front) {
          newRemeshingProject.flattening.front = convertPolyline3DToPath2D(curves.front.spline.controlPoints);
        }
        if (curves.interior) {
          newRemeshingProject.flattening.interior = convertPolyline3DToPath2D(curves.interior.spline.controlPoints);
        }
      }
      return newRemeshingProject;
    }

    case SET_TIP_THROAT_DISTANCE: {
      const tipThroatDistance: number = action.payload.tipThroatDistance;
      return {
        ...remeshingProject,
        remesh: {
          ...remeshingProject.remesh,
          throat_to_tip: tipThroatDistance,
        },
      };
    }

    case SET_BACKEND_FLATTENING_IMAGE_URL: {
      return {
        ...remeshingProject,
        flattening: {
          ...remeshingProject.flattening,
          rotation_angle: 0,
        },
        flattening_image_url: action.payload as string,
      };
    }

    case SET_BACKEND_REMESHED_LAST_URL: {
      return {
        ...remeshingProject,
        shoe_last_mesh_url: action.payload as string,
      };
    }

    case SET_FLATTENING_IMAGE_ORIENTATION: {
      const currAngle: number = action.payload.angle;
      const rotation_angle = remeshingProject.flattening && remeshingProject.flattening.rotation_angle !== undefined ?
        remeshingProject.flattening.rotation_angle + currAngle :
        currAngle;
      return {
        ...remeshingProject,
        flattening: {
          ...remeshingProject.flattening,
          rotation_angle,
        }
      };
    }

    case SET_FLATTENING_ALIGNMENT_POINTS: {
      const { throat, tip } = action.payload as { throat?: Vector3, tip?: Vector3 };
      inverseMatrix.getInverse(firstOf(state.flattenModel.dashboard.graphics.render3D())!.matrix);
      const inverseThroat = throat ? throat.clone().applyMatrix4(inverseMatrix) : undefined;
      const inverseTip = tip ? tip.clone().applyMatrix4(inverseMatrix) : undefined;
      return {
        ...remeshingProject,
        flattening: {
          ...remeshingProject.flattening,
          throat: inverseThroat ? [inverseThroat.x, inverseThroat.y] : undefined,
          tip: inverseTip ? [inverseTip.x, inverseTip.y] : undefined,
        },
      };
    }

    case SET_FLATTENING_CURVE: {
      const curveName = action.payload.curve as FlatCurvesNames;
      const path = action.payload.path as SplinePath | undefined;
      const newRemeshingProject = {
        ...remeshingProject,
        flattening: {
          ...remeshingProject.flattening,
        },
        shoe_last_flattening: undefined,
      };
      if (path) {
        (newRemeshingProject.flattening as any)[curveName] = convertPolyline3DToPath2D(path.controlPoints);
      } else if (curveName in newRemeshingProject.flattening) {
        delete (newRemeshingProject.flattening as any)[curveName];
      }
      return newRemeshingProject;
    }

    case IMPOSE_FLATTENING_BOUNDARIES: {
      const newBoundaryPolylines = action.payload as FundamentalBoundaryPolylines;

      return {
        ...remeshingProject,
        shoe_last_flattening: {
          back: convertPolyline2DToPath2D(newBoundaryPolylines.back),
          cone: convertPolyline2DToPath2D(newBoundaryPolylines.cone),
          exterior: convertPolyline2DToPath2D(newBoundaryPolylines.exterior),
          front: convertPolyline2DToPath2D(newBoundaryPolylines.front),
          interior: convertPolyline2DToPath2D(newBoundaryPolylines.interior),
        },
      };
    }

    default:
      return state.remeshingProject;
  }
};

export function initialState(): BackendRemeshingProject | undefined {
  return undefined;
}
