import { CameraOrientation, ProjectionPlane, Size } from "cadius-cadlib";
import { ReactiveView, SymmetryPlane } from "cadius-components";
import { InfinitePlane, IProjectManager, LastPID, SplinePath } from "cadius-db";
import { L_EPS_SQ } from "cadius-geo";
import { firstOf } from "cadius-stdlib";
import { Mesh, Vector2, Vector3 } from "three";

import {
  SET_FLATTENING_ALIGNMENT_POINTS,
  SET_FLATTENING_CURVE,
  SET_FLATTENING_CURVE_VISIBILITY,
  SET_FLATTENING_IMAGE,
  SET_FLATTENING_IMAGE_ORIENTATION,
  SET_FLATTENING_MODE,
} from "../actions/flatten-model";
import { IAction } from "../actions/interfaces";
import {
  RESET_REMESHED_LAST_AND_FLATTENING,
  SET_REMESHED_LAST,
  SET_TIP_THROAT_DISTANCE
} from "../actions/remesh-model";
import { DISABLE_ALL_INTERACTOR_STACK_SOURCE, SCENE_SOURCE_REFRESH } from "../actions/render";
import { ROUTE_FLATTEN_MODEL } from "../actions/routes-types";
import { SET_CAMERA_SIZE } from "../actions/ui";
import { makeEmptyIView } from "../scene";
import { FlattenMode, IApplicationState, IFlattenModelState } from "./interfaces";
import { FlatCurvesNames, FlattenCurves, updateView } from "./support/flatten";

/**
 * @brief The length in pixel for the margin of the flattening image.
 */
const MARGIN_LENGTH_PX = 10;

export const reducer = (state: IApplicationState, action: IAction): IFlattenModelState => {
  switch (action.type) {
    case SET_FLATTENING_IMAGE_ORIENTATION: {
      const tip = state.flattenModel.alignmentPoints.tip;
      if (!tip) {
        throw new Error("ASSERT: no tip point is set while attempting to rotate flattening image");
      }
      state.flattenModel.dashboard.graphics.transform(tip, action.payload.angle);
      return { ...state.flattenModel };
    }

    case SET_FLATTENING_MODE: {
      const { mode } = action.payload;
      const { graphics } = state.flattenModel.dashboard;

      if (mode === FlattenMode.align) {
        return {
          ...state.flattenModel,
          mode,
        };
      }

      let { throat, tip } = state.flattenModel.alignmentPoints;
      if (!throat || !tip) {
        throw new Error("ASSERT: invalid alignment points when moving to 'draw' sub-phase");
      }

      const dx = tip.x - throat.x;
      const dy = throat.y - tip.y;
      if (dx <= 0) {
        throw new Error("ASSERT: invalid alignment points");
      }
      const scale = state.theModel.bootstrap!.front!.spline.controlPoints[0].x / dx;

      const center = new Vector3(throat.x, tip.y, 0);
      const translation = center.clone().negate();

      throat = new Vector3(0, scale * dy, 0);
      tip = state.theModel.bootstrap!.front!.spline.controlPoints[0].clone();

      graphics.transform(center, undefined, scale, translation);

      const { cadView } = state.flattenModel.view;
      const newState = {
        ...state,
        flattenModel: {
          ...state.flattenModel,
          alignmentPoints: {
            throat,
            tip,
          },
          dashboard: {
            ...state.flattenModel.dashboard,
            graphics,
          },
          mode,
          view: {
            ...state.flattenModel.view,
            cadView: resetCadView(cadView, graphics),
          },
        }
      };

      return {
        ...newState.flattenModel,
        view: updateView(newState),
      };
    }

    case SET_TIP_THROAT_DISTANCE: {
      if (state.flattenModel.mode === FlattenMode.align) {
        return state.flattenModel;
      }
      const tip = state.flattenModel.alignmentPoints.tip!.clone();
      const throat = state.flattenModel.alignmentPoints.throat!.clone();

      const scale = state.theModel.bootstrap!.front!.spline.controlPoints[0].x / tip.x;
      throat.y *= scale;
      tip.x = state.theModel.bootstrap!.front!.spline.controlPoints[0].x;

      const { graphics } = state.flattenModel.dashboard;

      graphics.transform(undefined, undefined, scale);

      return {
        ...state.flattenModel,
        alignmentPoints: {
          ...state.flattenModel.alignmentPoints,
          throat,
          tip,
        },
        dashboard: {
          ...state.flattenModel.dashboard,
          graphics,
        },
        view: {
          ...state.flattenModel.view,
          cadView: resetCadView(state.flattenModel.view.cadView, graphics),
        }
      };
    }

    case SET_REMESHED_LAST: {
      let curves: FlattenCurves;
      const oldBootstrap: FlattenCurves | undefined = action.payload.oldBootstrap;

      if ((state.flattenModel.curves.areEmpty() && !oldBootstrap) || state.flattenModel.curves === oldBootstrap) {
        curves = action.payload.bootstrap;
      } else {
        curves = state.flattenModel.curves;
      }

      const newState = {
        ...state,
        flattenModel: {
          ...state.flattenModel,
          curves,
        },
      };
      return newState.flattenModel;
    }

    case DISABLE_ALL_INTERACTOR_STACK_SOURCE: {
      return {
        ...state.flattenModel,
        interactorSources: undefined,
      };
    }

    case RESET_REMESHED_LAST_AND_FLATTENING: {
      if (state.flattenModel.curves === action.payload.bootstrap) {
        return {
          ...state.flattenModel,
          curves: new FlattenCurves(),
        };
      }
      return state.flattenModel;
    }

    case ROUTE_FLATTEN_MODEL: {
      return {
        ...state.flattenModel,
        interactorSources: state.interactorSources,
      };
    }

    case SET_FLATTENING_ALIGNMENT_POINTS: {
      const { throat, tip } = action.payload;
      if (throat && tip && throat.distanceToSquared(tip) < L_EPS_SQ) {
        throw new Error("ERROR: alignment points are too close");
      }
      return {
        ...state.flattenModel,
        alignmentPoints: {
          ...state.flattenModel.alignmentPoints,
          throat,
          tip
        },
      };
    }

    case SET_FLATTENING_IMAGE: {
      const { image: flatImage, refreshCallback } = action.payload;
      const graphics = new SymmetryPlane(
        flatImage.width,
        flatImage.height,
        (flatImage as HTMLImageElement).src,
        refreshCallback
      );
      const { cadView } = state.flattenModel.view;
      return {
        ...state.flattenModel,
        alignmentPoints: {
          throat: undefined,
          tip: undefined,
        },
        dashboard: {
          ...state.flattenModel.dashboard,
          graphics,
        },
        flatImage,
        view: {
          ...state.flattenModel.view,
          cadView: resetCadView(cadView, graphics),
        },
      };
    }

    case SET_FLATTENING_CURVE: {
      const curve = action.payload.curve as FlatCurvesNames;
      const path = action.payload.path as SplinePath;

      const currCurve = state.flattenModel.curves[curve];
      if (currCurve && currCurve.spline === path) {
        return state.flattenModel;
      }

      const newState: IApplicationState = {
        ...state,
        flattenModel: {
          ...state.flattenModel,
          curves: state.flattenModel.curves.updateCurve(curve, path),
        }
      };

      return {
        ...newState.flattenModel,
        view: updateView(newState),
      };
    }

    case SET_FLATTENING_CURVE_VISIBILITY: {
      const visible = action.payload.visible;
      const curves = action.payload.curves as FlatCurvesNames[];
      const newState = {
        ...state,
        flattenModel: {
          ...state.flattenModel,
        },
      };

      for (const n of curves) {
        const curve = newState.flattenModel.curves[n];
        if (curve) {
          newState.flattenModel.curves[n] = curve.clone({ visible });
        }
      }
      newState.flattenModel.view = updateView(newState);
      return newState.flattenModel;
    }

    case SET_CAMERA_SIZE: {
      if (action.payload.cadView !== state.flattenModel.view.cadView) {
        return state.flattenModel;
      }
      const size = action.payload as Size;
      const view = state.flattenModel.view;
      const cadView = view.cadView.resize(size);

      const newState = {
        ...state.flattenModel,
        view: {
          ...view,
          cadView,
        },
      };

      if (state.flattenModel.flatImage) {
        newState.view.cadView = resetCadView(cadView, newState.dashboard.graphics);
      }

      return newState;
    }

    case SCENE_SOURCE_REFRESH: {
      return {
        ...state.flattenModel,
        view: updateView(state),
      };
    }

    default: {
      return state.flattenModel;
    }
  }
};

/**
 * @brief Returns a new `ReactiveView` that can see the whole flattening image.
 *
 * Change the camera zoom s.t. the whole flattenign image can be seen.
 *
 * To find the optimal zoom, we need to find the point on the projection surface in _NDC space_ that is the fartest from
 * the _NDC origin_.
 *
 * If we assume the four vertices of the triangle are $p_i^{(n)}=(x_i,y_i)$ with $ n \in {0, 1, 2, 3}$, then we can
 * compute $`l`$ as:
 *
 * ```math
 *  l = \max_{i}({\max(|x_i|, |y_i|)})
 * ```
 *
 * and the scaling factor $`s`$ becomes:
 *
 * ```math
 *  s = frac{s_{prev}}{l}
 * ```
 *
 * with $`s_{prev}`$ that is the previous scaling factor. Then we can scale further, to add a margin around the
 * flattening image with an additional $`\frac{1}{1 + c}`$, where $`c`$ is a fraction of one of the viewport dimensions.
 * The resulting scaling factor becomes:
 *
 * ```math
 *  s = frac{s_{prev}}{l + lc}
 * ```
 * @param cadView The current ReactiveView;
 * @param imagePlane The projectionSurface for the flattening sub-phase;
 * @returns a new camera whose zoom is computed s.t. it can see the whole flattening image.
 */
function resetCadView(cadView: ReactiveView, imagePlane: SymmetryPlane): ReactiveView {
  const translation = cadView.target.clone().negate();
  const delta = new Vector2(translation.x, translation.y);
  cadView = cadView.pan(delta);

  const plane = firstOf(imagePlane.render3D())!;
  if (!(plane as Mesh).geometry.boundingBox) {
    (plane as Mesh).geometry.computeBoundingBox();
  }
  const box = (plane as Mesh).geometry.boundingBox!.clone();
  box.applyMatrix4(plane.matrix);

  const [l, sizeCoordinateProp] = [box.min, box.max].map((p): [number, keyof Size] => {
    const projected = cadView.project(p);
    const x = Math.abs(projected.x);
    const y = Math.abs(projected.y);
    return x > y ? [x, "width"] : [y, "height"];
  }).reduce((max, curr) => curr[0] > max[0] ? curr : max, [-1, "width"]);

  const c = 2 * MARGIN_LENGTH_PX / cadView.size[sizeCoordinateProp];
  return cadView.zoom(cadView.zoomFactor / (l + l * c));
}

export const initialState = (projectManager: IProjectManager): IFlattenModelState => {
  return {
    alignmentPoints: {
      throat: undefined,
      tip: undefined,
    },
    curves: new FlattenCurves(),
    dashboard: {
      canvas: new InfinitePlane(
        LastPID,
        projectManager.newMark(LastPID),
        projectManager,
        "FlatteningCanvas",
        new ProjectionPlane(new Vector3(), new Vector3(0, 0, 1))
      ),
      graphics: new SymmetryPlane(),
    },
    mode: FlattenMode.align,
    view: {
      ...makeEmptyIView(0, CameraOrientation.side),
    },
  };
};
