import {
  ReactiveCadiusScene,
  ReactiveView,
  ResizeViewCallback,
  SubscribeRenderingRefresher,
  UnSubscribeRenderingRefresher,
  View3D,
} from "cadius-components";
import React from "react";
import styled from "styled-components";

const Div = styled.div`
  background-color: var(--colors-background-secondary);
  color: var(--colors-font-secondary);
  font-family: var(--typography-font-family-secondary);
  height: 100%;
  width: 100%;
`;

const DivInactive = styled.div`
  align-items: center; /* Vertical center alignment */
  background-color: var(--colors-background-primary);
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center; /* Horizontal center alignment */
  width: 100%;
`;

const FlexColumn = styled.div`
  background-color: var(--colors-atom-background-primary);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

interface IProps {
  cadView?: ReactiveView;
  flatteningRefURL?: string;
  scene?: ReactiveCadiusScene;

  subscribeRenderingRefresher?: SubscribeRenderingRefresher;
  unsubscribeRenderingRefresher?: UnSubscribeRenderingRefresher;

  onKeyDown?: (event: KeyboardEvent) => void;
  onKeyUp?: (event: KeyboardEvent) => void;
  onMouseDown?: (event: MouseEvent) => void;
  onMouseMove?: (event: MouseEvent) => void;
  onMouseUp?: (event: MouseEvent) => void;
  onResize: ResizeViewCallback;
  onWheel?: (event: WheelEvent) => void;
}

export const FlattenModel = (props: IProps) => {
  const {
    cadView,
    flatteningRefURL,
    onKeyDown,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onResize,
    onWheel,
    scene,
    subscribeRenderingRefresher,
    unsubscribeRenderingRefresher,
  } = props;

  return (
    <Div>
      <FlexColumn>
        {cadView && flatteningRefURL && scene ? (
          <View3D
            onKeyDown={onKeyDown}
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            onResize={onResize}
            onWheel={onWheel}
            subscribeRenderingRefresher={subscribeRenderingRefresher}
            unsubscribeRenderingRefresher={unsubscribeRenderingRefresher}
            view={{ id: 0, cadView, scene }}
            viewId={0}
          />
        ) : (
            <DivInactive>{"Upload an image of the flattened model"}</DivInactive>
          )}
      </FlexColumn>
    </Div>
  );
};
