import { Reducer } from "redux";

import { IAction } from "../actions/interfaces";
import { INITIAL_MODEL_FAIL, INITIAL_MODEL_SET } from "../actions/load-model";
import {
  RESET_REMESHED_LAST_AND_FLATTENING,
  SET_REMESHED_LAST,
  SET_REMESHING_GRID,
  SET_TIP_THROAT_DISTANCE
} from "../actions/remesh-model";
import { IWorkingModel } from "./interfaces";

export const reducer: Reducer<IWorkingModel, IAction> = (
  state: IWorkingModel = initialState(),
  action: IAction
): IWorkingModel => {
  switch (action.type) {
    case INITIAL_MODEL_SET:
      return {
        ...state,
        model: action.payload.model,
      };

    case INITIAL_MODEL_FAIL:
      return {
        ...state,
        model: undefined,
      };

    case SET_REMESHED_LAST: {
      const { bootstrap, remeshedLast } = action.payload;
      return {
        ...state,
        bootstrap,
        remeshedLast,
      };
    }

    case RESET_REMESHED_LAST_AND_FLATTENING: {
      // This action does not invalidate the bootrap to avoid losing the old bootstrap lines. The flattening reducer can
      // use them to check if the current flattening curves have never been manually modified and, hence, they must be
      // set to the new bootstrap.
      const remeshedLast = state.remeshedLast;
      if (remeshedLast) {
        remeshedLast.delete();
      }
      return {
        ...state,
        remeshedLast: undefined,
      };
    }

    case SET_REMESHING_GRID: {
      const { featherLength } = action.payload;

      return {
        ...state,
        featherLength,
      };
    }

    case SET_TIP_THROAT_DISTANCE: {
      const { tipThroatDistance } = action.payload;
      if (tipThroatDistance <= 0) {
        throw new Error("ASSERT: throat-to-tip distance cannot be negative nor zero");
      }

      const remeshedLast = state.remeshedLast;
      if (remeshedLast) {
        remeshedLast.delete();
      }
      return {
        ...state,
        remeshedLast: undefined,
        tipThroatDistance,
      };
    }

    default:
      return state;
  }
};

export const initialState = (): IWorkingModel => {
  return {
    featherLength: 0,
    model: undefined,
    tipThroatDistance: 0,
  };
}
