import { Color } from "three";

// The colors used in the flattening phase
export const flatteningPalette = new Map<string, Color>([
  ["interior", new Color().setStyle("#67BC1E")],
  ["exterior", new Color().setStyle("#A289D1")],
  ["cone", new Color().setStyle("#BC851E")],
  ["front", new Color().setStyle("#FF0031")],
  ["back", new Color().setStyle("#AA9C76")],
]);

// The colors used in the "align" sub-phase during flattening
export const flatteningPointsPalette = new Map<string, Color>([
  ["throat", new Color().setStyle("#FB8C00")],
  ["tip", new Color().setStyle("#F4511E")],
]);


// The colors used in the remeshing phase
export const remeshingPalette = new Map<string, Color>([
  ["cone", new Color().setStyle("#b75C00")],
  ["feather", new Color().setStyle("#9932CC")],
  ["throat", new Color().setStyle("#FB8C00")],
  ["tip", new Color().setStyle("#F4511E")],
]);
