/**
 * Events-related functions and action creators.
 *
 * TODO: move the code shared between cadius-cad3dr and cadius-forms to a
 * standalone package.
 *
 * TODO: reuse the CadiusDispatch, CadiusThunkAction, IAction defined in
 * cadius-components/input/testlib
 *
 * The functions in this module accept a DOM event and, given an existing set of
 * conditions, might perform one or more of the following:
 *
 * - create one or more Cad events
 * - discard the DOM event
 * - update some module variable that will affect subsequent function calls
 *
 * For each Cad event we create a redux action. Since there is NOT a 1-1
 * relationship between a DOM event and a Cad event, a function could accept a
 * single DOM event and dispatch multiple redux actions.
 *
 * Note: some of these functions do NOT create a Cad event, so they do NOT
 * create a redux action. Since they are still events-related functions, I think
 * it makes sense to leave them here for now.
 *
 * For the DOM events reference:
 * @see https://developer.mozilla.org/en-US/docs/Web/Events
 */
import { ScreenPosition } from "cadius-cadlib";
import {
  Cad,
  computePosition,
  computeZoomOffset,
  computeZoomScale,
  getModifiers,
  IView
} from "cadius-components";
import { ActionCreator } from "redux";
import { Vector2 } from "three";

import {
  CadiusDispatch,
  CadiusThunkAction,
  IAction,
} from "../actions/interfaces";

const PREFIX = "EVENT";

/**
 * Dispatched every time a Cad event is generated.
 */
export const HANDLE_CAD_EVENT = `${PREFIX} handle-CAD-event`;

/**
 * Dispatched every time a DOM event could not be converted in a Cad event (e.g.
 * a `mousedown` event with no `target`).
 */
export const INVALID_DOM_EVENT = `${PREFIX} invalid-DOM-event`;

// time window between 2 subsequent mouseup events that we wait, so we are able
// to distinguish a single "double click" from multiple "single click" events.
export const DOUBLE_CLICK_WINDOW_MS = 250;

// TODO: where to call event.stopPropagation() and/or event.preventDefault()
// and/or event.stopImmediatePropagation()? Both in invalidEvent() and in each
// DOM => Cad event action creator?

type DragObj = { x: number; y: number } | undefined;
let dragObj: DragObj;

interface IPressDatum {
  el: HTMLElement;
  position: Vector2;
  timestamp: Date;
}
export const pressMap = new Map<Cad.MouseButton, IPressDatum>();

interface IClickDatum {
  numClicks: number;
  timerId?: number;
  timestamp: number;
}
const clickMap = new Map<Cad.MouseButton, IClickDatum>();
for (const btn of [
  Cad.MouseButton.LEFT,
  Cad.MouseButton.RIGHT,
  Cad.MouseButton.MIDDLE,
]) {
  clickMap.set(btn, { numClicks: 0, timerId: undefined, timestamp: 0 });
}

export const onDragLeave = (event: DragEvent): IAction => {
  event.stopImmediatePropagation();

  if (!event.target) {
    return invalidEvent("This dragleave event has no target");
  }

  if (!dragObj) {
    return invalidEvent("drag was not previously set.");
  }

  const { x, y } = dragObj;
  dragObj = undefined;

  const modifiers = getModifiers(event);
  const position = new ScreenPosition(x, y);
  const timestamp = new Date();

  const evt = new Cad.DragLeaveEvent(event.target, modifiers, position, event.button, event.buttons);

  return { payload: { evt, timestamp }, type: HANDLE_CAD_EVENT };
};

/**
 * Handle a dragover DOM event.
 *
 * Note: we handle dragover by simply setting a module variable. We don't return
 * a redux action. So this is NOT an ction creator.
 */
export const onDragOver = (event: DragEvent) => {
  event.stopImmediatePropagation();

  if (!event.target) {
    return;
  }

  const position = computePosition(event);
  const { x, y } = position;
  dragObj = { x, y };
};

export const onFocus = (event: FocusEvent): IAction => {
  event.stopImmediatePropagation();
  return invalidEvent("The focus DOM event is currently not handled");
};

export const onKeyDown = (event: KeyboardEvent, view?: IView): IAction => {
  event.stopImmediatePropagation();

  if (!event.target) {
    return invalidEvent("This keydown event has no target");
  }

  return keyDownEvent(event, view);
};

export const onKeyUp = (event: KeyboardEvent, view?: IView): IAction => {
  event.stopImmediatePropagation();

  if (!event.target) {
    return invalidEvent("This keyup event has no target");
  }

  return keyUpEvent(event, view);
};

export const onMouseDown = (event: MouseEvent, view?: IView): IAction => {
  event.stopImmediatePropagation();

  if (!event.target) {
    return invalidEvent("This mousedown event has no target");
  }

  const button = getButton(event.button);
  if (button === undefined) {
    return invalidEvent("This mousedown event has no button");
  }

  return mousePressEvent(event, view);
};

export const onMouseMove = (event: MouseEvent, view?: IView): IAction => {
  event.stopImmediatePropagation();

  if (!event.target) {
    return invalidEvent("This mousemove event has no target");
  }

  return mouseMotionEvent(event, view);
};

export const onMouseOut = (event: MouseEvent, view?: IView): IAction => {
  event.stopImmediatePropagation();

  if (!event.target) {
    return invalidEvent("This mouseout event has no target");
  }

  return mouseOutEvent(event, view);
};

export const onMouseOver = (event: MouseEvent, view?: IView): IAction => {
  event.stopImmediatePropagation();

  if (!event.target) {
    return invalidEvent("This mouseover event has no target");
  }

  return mouseOverEvent(event, view);
};

export const onMouseUp = (event: MouseEvent, view?: IView): CadiusThunkAction<void> => {
  event.stopImmediatePropagation();

  return async (dispatch: CadiusDispatch): Promise<void> => {
    const button = getButton(event.button);

    let msg = "";

    if (!event.target) {
      msg = "This mouseup event has no target";
      dispatch(invalidEvent(msg));
      return;
    }

    if (!button) {
      msg = "This mouseup event has no button";
      dispatch(invalidEvent(msg));
      return;
    }

    const press = pressMap.get(button);
    if (!press) {
      msg = "pressMap was not set";
      dispatch(invalidEvent(msg));
      return;
    }
    pressMap.delete(button);

    dispatch(mouseReleaseEvent(event));

    if (event.target !== press.el) {
      msg = "mouseup target !== mousedown target (i.e. this is not a click)";
      console.warn(msg);
      return;
    }

    const now = Number(new Date());
    const click = clickMap.get(button)!;
    const elapsedMsecs = now - click.timestamp;
    click.timestamp = now;

    if (!click.timerId) {
      click.timerId = window.setTimeout(() => {
        click.timerId = undefined;
        click.numClicks = 0;
        click.timestamp = 0;
      }, DOUBLE_CLICK_WINDOW_MS);
    }

    if (elapsedMsecs <= DOUBLE_CLICK_WINDOW_MS) {
      dispatch(doubleClickEvent(event, view));
    } else {
      dispatch(singleClickEvent(event, view));
    }
  };
};

export const onWheel = (event: WheelEvent, view?: IView): IAction => {
  event.stopImmediatePropagation();

  if (!event.target) {
    return invalidEvent("This wheel event has no target");
  }

  return wheelEvent(event, view);
};

/**
 * Converts the value of `button` to a bitmask-compatible value.
 *
 * The MouseEvent DOM API does not keep the same values for the property
 * `button` (the button number that was pressed when the mouse event was fired)
 * and `buttons` (the buttons depressed when the mouse event was fired).
 * https://developer.mozilla.org/en-US/docs/Web/Events/mouseup#Properties
 */
export const getButton = (() => {
  const modMap = new Map<number, Cad.MouseButton>([
    [0, Cad.MouseButton.LEFT],
    [1, Cad.MouseButton.MIDDLE],
    [2, Cad.MouseButton.RIGHT],
  ]);
  return (button: number): Cad.MouseButton | undefined => {
    return modMap.get(button);
  };
})();

/**
 * Return an action that describes the reason why the DOM event could not be
 * converted into a Cad event.
 */
const invalidEvent: ActionCreator<IAction> = (reason?: string) => {
  return { payload: { reason }, type: INVALID_DOM_EVENT };
};

const singleClickEvent: ActionCreator<IAction> = (event: MouseEvent, view?: IView) => {
  const el = event.target as EventTarget;
  const modifiers = getModifiers(event);
  const position = computePosition(event);
  const button = getButton(event.button) as Cad.MouseButton;
  const timestamp = new Date();

  const evt = new Cad.MouseClickEvent(
    el,
    modifiers,
    position,
    button,
    event.buttons | button, // re-add button since this was removed during `mouseReleaseEvent`.
    view
  );
  return { payload: { evt, timestamp }, type: HANDLE_CAD_EVENT };
};

const doubleClickEvent: ActionCreator<IAction> = (event: MouseEvent, view?: IView) => {
  const el = event.target as EventTarget;
  const modifiers = getModifiers(event);
  const position = computePosition(event);
  const button = getButton(event.button) as Cad.MouseButton;
  const timestamp = new Date();

  const evt = new Cad.MouseDoubleClickEvent(
    el,
    modifiers,
    position,
    button,
    event.buttons | button, // re-add button since this was removed during `mouseReleaseEvent`.
    view
  );
  return { payload: { evt, timestamp }, type: HANDLE_CAD_EVENT };
};

const mouseReleaseEvent: ActionCreator<IAction> = (event: MouseEvent) => {
  const el = event.target as EventTarget;
  const modifiers = getModifiers(event);
  const position = computePosition(event);
  const button = getButton(event.button) as Cad.MouseButton;
  const timestamp = new Date();

  const evt = new Cad.MouseReleaseEvent(
    el,
    modifiers,
    position,
    button,
    event.buttons
  );
  return { payload: { evt, timestamp }, type: HANDLE_CAD_EVENT };
};

const mouseMotionEvent: ActionCreator<IAction> = (event: MouseEvent, view?: IView) => {
  const el = event.target as EventTarget;
  const modifiers = getModifiers(event);
  const position = computePosition(event);
  const button = getButton(event.button) as Cad.MouseButton;
  const timestamp = new Date();

  const evt = new Cad.MouseMotionEvent(
    el,
    modifiers,
    position,
    button,
    event.buttons,
    view
  );

  return { payload: { evt, timestamp }, type: HANDLE_CAD_EVENT };
};

const mousePressEvent: ActionCreator<IAction> = (event: MouseEvent, view?: IView) => {
  const el = event.target as HTMLElement;
  const modifiers = getModifiers(event);
  const position = computePosition(event);
  const button = getButton(event.button) as Cad.MouseButton;
  const timestamp = new Date();

  pressMap.set(button, { el, position, timestamp });

  const evt = new Cad.MousePressEvent(
    el,
    modifiers,
    position,
    button,
    event.buttons,
    view
  );
  return { payload: { evt, timestamp }, type: HANDLE_CAD_EVENT };
};

const keyDownEvent: ActionCreator<IAction> = (event: KeyboardEvent, view?: IView) => {
  const el = event.target as HTMLElement;
  const modifiers = getModifiers(event);
  const timestamp = new Date();

  const evt = new Cad.KeyDownEvent(
    el,
    modifiers,
    event.key,
    event.code,
    event.repeat,
    view
  );
  return { payload: { evt, timestamp }, type: HANDLE_CAD_EVENT };
};

const keyUpEvent: ActionCreator<IAction> = (event: KeyboardEvent, view?: IView) => {
  const el = event.target as HTMLElement;
  const modifiers = getModifiers(event);
  const timestamp = new Date();

  const evt = new Cad.KeyUpEvent(el, modifiers, event.key, event.code, view);
  return { payload: { evt, timestamp }, type: HANDLE_CAD_EVENT };
};

const wheelEvent: ActionCreator<IAction> = (event: WheelEvent, view?: IView) => {
  const el = event.target as HTMLElement;
  const modifiers = getModifiers(event);
  const position = computePosition(event);
  const scale = computeZoomScale(event.deltaMode);
  const offset = computeZoomOffset(scale, event.deltaX, event.deltaY);
  const timestamp = new Date();

  const evt = new Cad.MouseWheelEvent(
    el,
    modifiers,
    position,
    offset,
    event.buttons,
    view
  );
  return { payload: { evt, timestamp }, type: HANDLE_CAD_EVENT };
};

const mouseOutEvent: ActionCreator<IAction> = (event: MouseEvent, view?: IView) => {
  const el = event.target as HTMLElement;
  const modifiers = getModifiers(event);
  const position = computePosition(event);
  const button = getButton(event.button) as Cad.MouseButton;
  const timestamp = new Date();

  const evt = new Cad.MouseOutEvent(
    el,
    modifiers,
    position,
    button,
    event.buttons,
    view
  );
  return { payload: { evt, timestamp }, type: HANDLE_CAD_EVENT };
};

const mouseOverEvent: ActionCreator<IAction> = (event: MouseEvent, view?: IView) => {
  const el = event.target as HTMLElement;
  const modifiers = getModifiers(event);
  const position = computePosition(event);
  const button = getButton(event.button) as Cad.MouseButton;
  const timestamp = new Date();

  const evt = new Cad.MouseOverEvent(
    el,
    modifiers,
    position,
    button,
    event.buttons,
    view
  );
  return { payload: { evt, timestamp }, type: HANDLE_CAD_EVENT };
};
