import { UploadLocalImage } from "cadius-components";
import { connect } from "react-redux";

import { setBackendFlatteningImageURL } from "../actions/backend";
import { setFlatteningImage } from "../actions/flatten-model";
import { CadiusDispatch } from "../actions/interfaces";
import { saveProjectOnBackend, uploadFlatteningImage } from "../actions/save-project";
import { IApplicationState } from "../reducers/interfaces";

function mapStateToProps(state: IApplicationState) {
  return {
    imgSrc: state.flattenModel.flatImage ? state.flattenModel.flatImage.src : undefined,
    labelActive: "Release to Upload",
    labelInactive: "Upload Flattening Image",
  };
}

const mapDispatchToProps = (dispatch: CadiusDispatch) => {
  return {
    makeImgSrcFromFile: async (file: File) => {
      const uri = window.URL.createObjectURL(file);
      const img = document.createElement("img");
      img.src = uri;
      dispatch(setFlatteningImage(img));
      const url = await dispatch(uploadFlatteningImage(file));
      dispatch(setBackendFlatteningImageURL(url));
      dispatch(saveProjectOnBackend());
    }
  }
}

const enhance = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const UploadFlatteningImage = enhance(UploadLocalImage);
