import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { IQuadViewProps, QuadView, ReactiveView } from "cadius-components";

import {
  onKeyDown,
  onKeyUp,
  onMouseDown,
  onMouseMove,
  onMouseOut,
  onMouseOver,
  onMouseUp,
  onWheel,
} from "../actions/events";
import { CadiusDispatch } from "../actions/interfaces";
import { setCameraSize } from "../actions/ui";
import { IApplicationState } from "../reducers/interfaces";

function mapStateToProps(state: IApplicationState): IQuadViewProps {
  return { views: state.alignModel.views };
}

function mapDispatchToProps(dispatch: CadiusDispatch): Partial<IQuadViewProps> {
  const actionCreators = bindActionCreators(
    {
      onKeyDown,
      onKeyUp,
      onMouseDown,
      onMouseMove,
      onMouseOut,
      onMouseOver,
      onMouseUp,
      onWheel,
    },
    dispatch
  );

  return {
    ...actionCreators,
    onResize: (cadView: ReactiveView, width: number, height: number) => {
      dispatch(setCameraSize(cadView, width, height));
    },
  };
}

const enhance = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const AlignModel = enhance(QuadView);
