import {
  Key,
  LMB,
  Shift,
} from "cadius-components";
import React from "react";
import { Vector3 } from "three";

import { FlattenMode } from "../../reducers/interfaces";
import "./help.scss";

/**
 * @brief A list of actions available in the _aligning phase_
 */
export const AligningHelp = () => (
  <div className="box">
    <div className="stack">
      <h1 className="help-title">Help</h1>
      <div>
        <LMB />to rotate the last (orthographic views only)
      </div>
      <div>
        <LMB />+<Shift />to move the last around
      </div>
    </div>
  </div>
);


interface IProps {
  mode: FlattenMode;
  isRotating: boolean;
  throat: Vector3 | undefined;
  tip: Vector3 | undefined;
}

/**
 * @brief A list of actions available in the _flattening phase_
 */
export const FlatteningHelp = (props: IProps) => {
  if (props.mode === FlattenMode.align) {
    const bothPointsSet = !!props.throat && !!props.tip;
    if (!bothPointsSet) {
      return (
        <div className="box">
          <div className="stack">
            <h1 className="help-title">Help</h1>
            <span><LMB />{`To set the ${props.throat ? "tip" : "throat"} point`}</span>
          </div>
        </div>
      )
    } else if (props.isRotating) {
      return (
        <div className="box">
          <div className="stack">
            <h1 className="help-title">Help</h1>
            <span><Key value="R" />or<LMB />To stop rotating the image</span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="box">
          <div className="stack">
            <h1 className="help-title">Help</h1>
            <span><LMB /> to hook and move any point</span>
            <span><Key value="R" />To start rotating the image</span>
          </div>
        </div>
      );
    }
  }
  return (
    <div className="box">
      <h1 className="help-title">Help</h1>
      <span><Shift />+<LMB />To pan</span>
    </div>
  )
}
