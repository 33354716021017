// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/cadius-components/dist/components.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{background-color:var(--colors-background-primary);font-family:var(--typography-font-family-default);height:100vh;width:100%;margin:0;overflow:hidden}.ui div,.ui h1,.ui h2,.ui h3,.ui h4,.ui h5,.ui h6,.ui p,.ui span{color:var(--colors-font-primary)}", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA,KACE,iDAAA,CACA,iDAAA,CACA,YAAA,CACA,UAAA,CACA,QAAA,CACA,eAAA,CAGF,iEASE,gCAAA","sourcesContent":["@import \"../../../node_modules/cadius-components/dist/components.css\";\n\nbody {\n  background-color: var(--colors-background-primary);\n  font-family: var(--typography-font-family-default);\n  height: 100vh;\n  width: 100%;\n  margin: 0;\n  overflow: hidden;\n}\n\n.ui div,\n.ui h1,\n.ui h2,\n.ui h3,\n.ui h4,\n.ui h5,\n.ui h6,\n.ui p,\n.ui span {\n  color: var(--colors-font-primary);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
