import { Matrix4, Quaternion, Vector3 } from "three";

/**
 * Given the three components of a affine transformation, translation, (uniform) scaling and rotation, this function
 * constructs the corresponding 4x4 matrix.
 * @export
 * @param {Vector3} translation The translation component of the affine transformation.
 * @param {Quaternion} rotation The rotation component of the affine transformation.
 * @param {number} scale The scaling factor of the affine transformation.
 * @param {Matrix4} [output] Optional output variable where to store the resulting matrix.
 * @returns {Matrix4} The 4x4 matrix associated to the given affine transformation.
 */
export const makeMatrix = (() => {
  const mat = new Matrix4();

  return (translation: Vector3, rotation: Quaternion, scale: number, output?: Matrix4): Matrix4 => {
    if (!output) {
      output = new Matrix4();
    }
    output.identity();
    output.premultiply(mat.makeRotationFromQuaternion(rotation));
    output.premultiply(mat.makeScale(scale, scale, scale));
    output.premultiply(mat.makeTranslation(translation.x, translation.y, translation.z));
    return output;
  };
})();
