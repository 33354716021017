import { IView3DProps, ReactiveView, View3D } from "cadius-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  onKeyDown,
  onMouseDown,
  onMouseMove,
  onMouseOut,
  onMouseOver,
  onMouseUp,
  onWheel,
} from "../actions/events";
import { CadiusDispatch } from "../actions/interfaces";
import { setCameraSize } from "../actions/ui";
import { IApplicationState } from "../reducers/interfaces";

function mapStateToProps(state: IApplicationState): IView3DProps {
  return { view: state.remeshModel.view, viewId: 0 };
}

function mapDispatchToProps(dispatch: CadiusDispatch): Partial<IView3DProps> {
  const actionCreators = bindActionCreators(
    {
      onKeyDown,
      onMouseDown,
      onMouseMove,
      onMouseOut,
      onMouseOver,
      onMouseUp,
      onWheel,
    },
    dispatch
  );

  return {
    ...actionCreators,
    onResize: (cadView: ReactiveView, width: number, height: number) => {
      dispatch(setCameraSize(cadView, width, height));
    },
  };
}

const enhance = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const RemeshModel = enhance(View3D);
