import { ReactiveView } from "cadius-components";
import { ActionCreator } from "redux";

import { CadiusDispatch, CadiusThunkAction, IAction } from "./interfaces";
import { sceneSourceRefresh } from "./render";

const PREFIX = "UI";

/**
 * Dispatched when we need to provide a global feedback; the resulting ui will
 * be very prominent and block any further interactions with the app.
 */
export const UPDATE_GLOBAL_FEEDBACK = `${PREFIX} update-global-feedback`;

export const SET_CAMERA_SIZE = 'set-camera-size';

/**
 * Thunk action creator to show a global message as soon as possible and the go on with the rest.
 * It dispatches the new message and then terminates the current rendering loop cycle, but it also
 * sets a timer to resolve other computations.
 *
 * Example of usage:
 * ```typescript
 *   ...
 *   // inside another thunk action creator
 *   await dipatch(showGlobalFeedback("very long processing...", "Whether forecast"));
 *   const res = computeTomorrow();
 *   await dipatch(showGlobalFeedback("Tomorrow it will rain! ", "Whether forecast"));
 *   setTimeout(() => { dipatch(hideGlobalFeedback()); }, 3000);
 *   ...
 * ```
 * @export
 * @param {string} [info] Optional message to show.
 * @param {string} [title] Optional title to show.
 * @returns {CadiusThunkAction<void>}
 */
export function showGlobalFeedback(
  info?: string,
  title?: string
): CadiusThunkAction<void> {
  return (dispatch: CadiusDispatch): Promise<void> => {
    return new Promise<void>((resolve) => {
      dispatch({
        payload: { info, title, open: true, progress: undefined },
        type: UPDATE_GLOBAL_FEEDBACK,
      });
      dispatch(sceneSourceRefresh()); // necessary to redraw the views as nobody does for `UPDATE_GLOBAL_FEEDBACK`
      setTimeout(() => {
        resolve();
      }, 0);
    });
  };
}

/**
 * Thunk action creator to show a global progress as soon as possible and then go on with the rest.
 * It dispatches the new message and then terminates the current rendering loop cycle, but it also
 * sets a timer to resolve other computations.
 *
 * Example of usage:
 * ```typescript
 *   ...
 *   // inside another thunk action creator
 *   await dispatch(showGlobalProgress("very long processing...", "Whether forecast", 0));
 *   const res = computeTomorrow();
 *   await dispatch(showGlobalProgress("very long processing...", "Whether forecast", 0.5));
 *   const res2 = computeAfterTomorrow(res);
 *   await dispatch(
 *     showGlobalProgress("Tomorrow it will rain! But the day after tomorrow it will snow!!", "Whether forecast", 1));
 *   setTimeout(() => { dispatch(hideGlobalFeedback()); }, 3000);
 *   ...
 * ```
 * @export
 * @param {string} [info] Optional message to show.
 * @param {string} [title] Optional title to show.
 * @param {number} [progress]
 * @returns {CadiusThunkAction<void>}
 */
export function showGlobalProgress(
  info?: string,
  title?: string,
  progress?: number
): CadiusThunkAction<void> {
  return (dispatch: CadiusDispatch): Promise<void> => {
    return new Promise<void>((resolve) => {
      dispatch({
        payload: { info, title, open: true, progress },
        type: UPDATE_GLOBAL_FEEDBACK,
      });
      dispatch(sceneSourceRefresh()); // necessary to redraw the views as nobody does for `UPDATE_GLOBAL_FEEDBACK`
      setTimeout(() => {
        resolve();
      }, 0);
    });
  };
}

export const showGlobalAlert: ActionCreator<IAction> = (
  info?: string,
  title?: string
): IAction => {
  console.log("TODO implement the global alert");
  return {
    payload: { info, title, open: true, progress: undefined },
    type: UPDATE_GLOBAL_FEEDBACK,
  };
};

export const hideGlobalFeedback: ActionCreator<IAction> = (): IAction => {
  return {
    payload: { open: false },
    type: UPDATE_GLOBAL_FEEDBACK,
  };
};

/**
 * @brief Dispatched when a camera needs to be resized.
 *
 * @param width The new width
 * @param height The new height
 */
export const setCameraSize = (
  cadView: ReactiveView,
  width: number,
  height: number
) => {
  return { payload: { cadView, width, height }, type: SET_CAMERA_SIZE };
};
