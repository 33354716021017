import { ActionCreator } from "redux";

import { Interactor } from "cadius-components";

import { IAction } from "./interfaces";

const PREFIX = "INTERACTOR";

export const START_INTERACTOR = `${PREFIX} start-interactor`;

export const RESET_INTERACTORS = `${PREFIX} reset-interactors`;

export const resetInteractors: ActionCreator<IAction> = (
  interactors: Interactor[] = []
) => {
  return {
    payload: { interactors },
    type: RESET_INTERACTORS,
  };
};

export const startInteractor: ActionCreator<IAction> = (interactor: Interactor) => {
  return {
    payload: { interactor },
    type: START_INTERACTOR,
  };
};
