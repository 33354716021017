import { RemeshingProject } from "cadius-backend";
import { Render3DContext } from "cadius-cadlib";
import {
  CadiusSceneSource,
  CurveSource,
  IDialogProps,
  InteractorStack,
  InteractorStackSource,
  IView,
  PointSource,
  SymmetryPlane,
} from "cadius-components";
import { ConeAndFeatherPaths, InfinitePlane, IProjectManager, Last, LatitudeAndLongitudePaths, SplinePath } from "cadius-db";
import { CppRemeshedLast } from "cadius-geo3d";
import { Mesh, Quaternion, Vector3 } from "three";

import { FlattenCurves } from "./support/flatten";

export interface IAlignModelView extends IView {
  readonly renderedLast?: Mesh;
}

export type AlignModelViews = [
  IAlignModelView,
  IAlignModelView,
  IAlignModelView,
  IAlignModelView
];

export interface IUIState {
  /**
   * We provide some visual feedback to the user by the means of a Dialog.
   *
   * The dialog gives the user some background information on the ongoing
   * operation (e.g. fetching data, adding object to the Three.js scene).
   */
  readonly feedback: IDialogProps;

  /**
   * @brief Stores the configuration for a 3D view; this includes filters, debug objects, and envMaps.
   */
  readonly ctx: Render3DContext;
}

export interface IOpenModelState {
  // if the load fails this message can be used to give the user some clue
  readonly failureMessage: string;
}

export interface ITransform {
  // Position of the model (in world coordinates)
  readonly position: Vector3;

  // Rotation of the model (in radians)
  readonly rotation: Quaternion;

  // Scale of the model (adimensional)
  readonly scale: number;
}

export interface IWorkingModel {
  // The model is undefined until the user loads it
  readonly model?: Last;

  // The length of the shoe bottom (available as soon as the grid has been computed)
  readonly featherLength: number;

  // the distance between *throat* and *tip*, the fundamental points used during the flattening
  readonly tipThroatDistance: number;

  readonly remeshedLast?: CppRemeshedLast;

  // The parameterization curves as they were initially provided by the automatic parameterization
  readonly bootstrap?: FlattenCurves;
}

export interface IAlignModelState {
  // The temporary transformation applied by the roto-translator interactor
  readonly localTransformation: ITransform;

  // The 4 views (camera + scene)
  readonly views: AlignModelViews;
}

export interface RemeshModelSources {
  // Decorations include the 3D axes and ligths
  readonly decorations: CadiusSceneSource;

  // The following should be available only during the remeshing phase.
  readonly interactors?: CadiusSceneSource;

  readonly last: CadiusSceneSource;

  readonly lights: CadiusSceneSource;

  // These include both fundamental curves
  readonly gridLines: CurveSource[];

  readonly featherEdge?: CurveSource;

  readonly coneEdge?: CurveSource;

  readonly symmetryPlane: CadiusSceneSource;

  // `Throat` and `tip` are shown so that the user can check them.
  readonly throat?: PointSource;
  readonly tip?: PointSource;
}

export interface IRemeshModelState {

  // The spline that runs along the featherEdge. This is initially undefined
  readonly featherEdge?: SplinePath;

  // The spline that runs along the coneEdge. This is initially undefined
  readonly coneEdge?: SplinePath;

  readonly view: IView;
  readonly sources?: RemeshModelSources;

  // The following two objects represent the remeshing grid.
  readonly latitudeAndLongitudePaths?: LatitudeAndLongitudePaths;
  readonly coneAndFeatherPaths?: ConeAndFeatherPaths;
}

// List the sub-phases available during flattening.
export enum FlattenMode {
  align, // The user chooses 2 control points to align the flattening image.
  draw, // The user edits the flattening lines.
}

export interface IFlattenModelState {
  // The image of the manually flattened model, if undefined the user must select an image to proceed with the
  // flattening
  readonly flatImage?: HTMLImageElement;

  // The fundamental points used to align the flat image
  readonly alignmentPoints: {
    throat?: Vector3;
    tip?: Vector3;
  };

  /**
   * The sub-phase of the flattening phase:
   * - align the control points
   * - draw the fundamental lines.
   */
  readonly mode: FlattenMode;

  // the view for the 3d render
  readonly view: IView;

  // the plane textured with the flattening image
  readonly dashboard: {
    canvas: InfinitePlane;
    graphics: SymmetryPlane;
  };

  // The curves the parameterization is composed of.
  readonly curves: FlattenCurves;

  // The following is optional since this source can be turned on/off depending on the current phase
  readonly interactorSources?: InteractorStackSource;
}

/**
 * This interface enhances the backend `RemeshingProject` with information about the state of
 * synchronizing it with our application: when we fetch the backend project and compute/apply
 * the differences (different curves, model transformation, etc.) between that and the entities
 * currently in the application state,we *must* avoid sending the current *unsynced* project
 * to the backend by actions that change such entities (`saveProjectOnBackend()` is dispatched
 * by such thunk action creators).
 * Alternatively, we could delete the current remeshing project from the application state
 * so that it isn't sent back. However, this would cause a refresh of the webpage because some
 * UI components react to the difference between the project ID in the URL and the one stored
 * in the remeshing project.
 * @export
 * @interface BackendRemeshingProject
 * @extends {RemeshingProject}
 */
export interface BackendRemeshingProject extends RemeshingProject {
  /**
   * Flag used to determine the state of validity of the remeshing project stored in the application
   * state: when `partial`, the project isn't equal to the one at the backend but it is being
   * synchronized through several actions applying diff changes in the entities; when `complete`,
   * the project is synchronized and equal to the one fetched.
   * @type {("complete" | "partial")}
   * @memberof BackendRemeshingProject
   */
  loading: "complete" | "partial";
}

export interface IApplicationState {
  // project manager for PID and Mark generation
  readonly projectManager: IProjectManager;

  // the serialized project stored at the backend
  readonly remeshingProject?: BackendRemeshingProject;

  // the local model we need to work on
  readonly theModel: IWorkingModel;

  // step 0 - the user selects a model to work on
  readonly openModel: IOpenModelState;
  // step 1 - the user aligns the model
  readonly alignModel: IAlignModelState;
  // step 2 - the user performs a remeshing of the last
  readonly remeshModel: IRemeshModelState;
  // step 3 - the user sees/edits the curves (e.g. feather edge) on the flattened last
  readonly flattenModel: IFlattenModelState;

  // global feeback for the user (e.g. dialogs, alerts, progress bars)
  readonly ui: IUIState;

  readonly interactorStack: InteractorStack;
  /**
   * There can be only just a single interactor stack source instance for the current application, so we put it here.
   *
   * Since an `InteractorStackSource` simply iterates over the given `InteractorStack` instance given and ask
   * for the objects to render, if we use the same `InteractorStack` instance across the whole application,
   * the same 3D object will be added to different scenes with different renderer and 3D objects sharing between
   * renderer different scenes with different renderer is not supported by ThreeJS.
   */
  readonly interactorSources: InteractorStackSource;
}
