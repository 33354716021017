import { client } from "cadius-backend";
import { CadiusDispatch, CadiusThunkAction } from "../actions/interfaces";

import { IApplicationState } from "../reducers/interfaces";
import { setProject } from "./backend";
import { sceneSourceRefresh } from "./render";

/**
 * Thunk action creator to save the current project at the backend. The backend returns the updated project (which
 * may differ from the sent one if someone else has sent other changes in the meantime). In this case a diff must
 * be applied [TODO].
 * @export
 * @returns {CadiusThunkAction<void>}
 */
export function saveProjectOnBackend(): CadiusThunkAction<void> {
  return async (dispatch: CadiusDispatch, getState: () => IApplicationState): Promise<void> => {
    let state = getState();
    // clearly, if the remeshing project loading is *partial* (meaning that some action creator
    // has dispatched `saveProjectOnBackend()` when this action creator was dispatching changes
    // to sync with the project coming from the backend), we don't want to send back the current,
    // unsynced and incomplete project
    if (!state.remeshingProject || state.remeshingProject.loading === "partial") {
      return;
    }
    const np = await client().remeshes.update(state.remeshingProject.id, state.remeshingProject);
    state = getState();
    // this operation doesn't change the remeshing project, but set it as not ready:
    // it is used to avoid sending it to the backend, when applying the diff (made by
    // dispatching actions which would dispatch `saveProjectOnBackend()` themselves,
    // thing clearly to avoid now since we're actually syncing with that from the backend
    // and don't want to send back a *temporary*, *broken* project)
    dispatch(setProject({ ...state.remeshingProject!, loading: "partial" }));
    // TODO apply project diff
    dispatch(setProject({ ...np, loading: "complete" }));
    dispatch(sceneSourceRefresh());
  };
}

/**
 * Thucnk action creator to send a (new) flattening image to the backend. The backend will return the URL of the
 * stored image.
 * In order to get the URL back, just resolve the promise returned by the dispatcher:
 * ```typescript
 *   const url = await dispatch(uploadFlatteningImage(file));
 * ```
 * @export
 * @param {File} file
 * @returns {CadiusThunkAction<string>}
 */
export function uploadFlatteningImage(file: File): CadiusThunkAction<string> {
  return async (dispatch: CadiusDispatch, getState: () => IApplicationState): Promise<string> => {
    let data: ArrayBuffer;
    if ((file as any).arrayBuffer) {
      data = await (file as any).arrayBuffer();
    } else {
      const p = new Promise<ArrayBuffer>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async (evt: ProgressEvent) => {
          if (evt.type === "load") {
            resolve(reader.result as ArrayBuffer);
          } else {
            reject("unable to load file")
          }
        };
        reader.readAsArrayBuffer(file);
      });
      data = await p;
    }
    const downloadUrl = await client().remeshes.changeFlatteningImage(getState().remeshingProject!.id, data);
    return downloadUrl.url;
  };
}

/**
 * Thunk action creator to upload the remeshed Last on the backend. It returns the URL of the remeshed Last stored.
 * @export
 * @returns {CadiusThunkAction<string>}
 */
export function uploadRemeshedLast(): CadiusThunkAction<string> {
  return async (dispatch: CadiusDispatch, getState: () => IApplicationState): Promise<string> => {
    const id = getState().remeshingProject!.id;
    const remeshedLast = getState().theModel.remeshedLast!;
    const downloadUrl = await client().remeshes.changeShoeLastMesh(id, remeshedLast.toObj());
    return downloadUrl.url;
  };
}
