import { Matrix4, Quaternion, Vector3 } from "three";

/**
 * @brief returns true if the given transformation does not change a geometry, false otherwise.
 *
 * @param position
 * @param rotation
 * @param scale
 * @returns true if the transformation results is the identity matrix, false otherwise.
 */
export const isIdentity = (() => {
  const mat = new Matrix4();
  const identity = new Matrix4();
  const scaling = new Vector3();
  const defaultTranslation = new Vector3();
  const defaultRotation = new Quaternion();

  return (translation?: Vector3, rotation?: Quaternion, scale?: number): boolean => {
    mat.compose(translation || defaultTranslation, rotation || defaultRotation, scaling.setScalar(scale !== undefined ? scale : 1));
    return mat.equals(identity);
  };
})();
