import { isTriangleSoupLast } from "cadius-cadlib";
import { InteractorStackSource } from "cadius-components";
import { IProjectManager } from "cadius-db";
import { Reducer } from "redux";

import { RESET_INITIAL_STATE } from "../actions/app";
import { IAction } from "../actions/interfaces";
import { IAlignModelState, IApplicationState } from "../reducers/interfaces";
import { initialState as alignModelInitialState, reducer as alignModelReducer } from "./align-model";
import { initialState as backendInitialState, reducer as backendReducer } from "./backend";
import { initialState as flattenModelInitialState, reducer as flattenModelReducer } from "./flatten-model";
import { initialState as interactorStackInitialState, reducer as interactorStackReducer } from "./interactor-stack";
import { initialState as openModelInitialState, reducer as openModelReducer } from "./load-model";
import { initialState as remeshModelInitialState, reducer as remeshModelReducer } from "./remesh-model";
import { initialState as uiInitialState, reducer as uiReducer } from "./ui";
import { initialState as modelInitialState, reducer as modelReducer } from "./working-model";

const reducerMap = {
  openModel: openModelReducer,
  theModel: modelReducer,
  ui: uiReducer,
};

function combine(state: any, action: IAction): IApplicationState {
  const newState: any = { ...state };
  let changed = false;
  for (const name of Object.keys(reducerMap)) {
    const reducer = (reducerMap as any)[name];
    const partial: any = state ? state[name] : undefined;
    newState[name] = reducer(partial, action);
    if (newState[name] !== partial) {
      changed = true;
    }
  }
  return changed ? newState : state;
}

export const rootReducer: Reducer<IApplicationState, IAction> = (state, action) => {
  if (state === undefined || action.type === RESET_INITIAL_STATE) {
    if (state !== undefined && state.theModel.model) {
      const mesh = state.theModel.model.geometry();
      if (isTriangleSoupLast(mesh)) {
        mesh.delete();
      }
      if (state.theModel.remeshedLast) {
        state.theModel.remeshedLast.delete();
      }
    }
    return initialState();
  }

  let newState: IApplicationState = combine(state, action);
  let changed = newState !== state;
  const AMS: IAlignModelState = state.alignModel;

  const newAMS = alignModelReducer(newState, action);
  if (newAMS !== AMS) {
    if (!changed) {
      newState = { ...newState };
    }
    (newState as any).alignModel = newAMS;
    changed = true;
  }

  const newRNMS = remeshModelReducer(newState, action);
  if (newRNMS !== newState.remeshModel) {
    if (!changed) {
      newState = { ...newState };
    }
    (newState as any).remeshModel = newRNMS;
    changed = true;
  }

  const newFMS = flattenModelReducer(newState, action);
  if (newFMS !== newState.flattenModel) {
    if (!changed) {
      newState = { ...newState };
    }
    (newState as any).flattenModel = newFMS;
    changed = true;
  }

  newState = interactorStackReducer(newState, action);

  const newBS = backendReducer(newState, action);
  if (newBS !== newState.remeshingProject) {
    if (!changed) {
      newState = { ...newState };
    }
    (newState as any).remeshingProject = newBS;
    changed = true;
  }

  return newState;
};

function initialState(): IApplicationState {
  const interactorStack = interactorStackInitialState();
  const projectManager = new IProjectManager();
  return {
    alignModel: alignModelInitialState(),
    flattenModel: flattenModelInitialState(projectManager),
    interactorSources: new InteractorStackSource(interactorStack),
    interactorStack,
    openModel: openModelInitialState(),
    projectManager,
    remeshModel: remeshModelInitialState(),
    remeshingProject: backendInitialState(),
    theModel: modelInitialState(),
    ui: uiInitialState(),
  };
}
