import { CadiusDispatch, CadiusThunkAction } from "./interfaces";

const PREFIX = "[RENDER]";

export const SCENE_SOURCE_REFRESH = `${PREFIX} refresh-sources-in-scene`;
export const DISABLE_ALL_INTERACTOR_STACK_SOURCE = `${PREFIX} disable-all-interactor-stack-source`;

// TODO: why does this have to be async? Comment it.
export const sceneSourceRefresh = (): CadiusThunkAction<void> => {
  return async (dispatch: CadiusDispatch): Promise<void> => {
    const fn = () => {
      dispatch({
        type: SCENE_SOURCE_REFRESH,
      });
    };
    setTimeout(fn, 0);
  };
};

export const disableAllInteractorStackSources = () => {
  return { type: DISABLE_ALL_INTERACTOR_STACK_SOURCE };
}
