import { IAction } from "./interfaces";

/**
 * Action dispatched when (re)loading a project. It is necessary especially when loading a project
 * after having already loaded another one, in order to delete from the state anything belonging to
 * the previous project and avoiding getting wrong side effects.
 */
export const RESET_INITIAL_STATE = "RESET_INITIAL_STATE";

/**
 * Action creator to dispatch a `RESET_INITIAL_STATE`: dispatch this when loading a project in order
 * to delete from the state anything that could cause wrong side effects.
 * @export
 * @returns {IAction}
 */
export function resetInitialState(): IAction {
  return {
    type: RESET_INITIAL_STATE,
  };
}
