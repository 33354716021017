import {
  Chip,
  IconName,
  PrimaryButton,
  ToolButton,
} from "cadius-components";
import React from "react";
import { Vector3 } from "three";

import { flatteningPointsPalette } from "../palettes";
import { FlattenMode } from "../reducers/interfaces";

interface IProps {
  /**
   * The throat point (calzata) in world coordinates, i.e. mm.
   * It's `undefined` if the user has not yet drawn it.
   */
  throatPoint?: Vector3;

  /**
   * The distance between the throat point and the tip point, in mm.
   */
  throatToTipDistance?: number;

  /**
   * The tip point (punta) in world coordinates, i.e. mm.
   * It's `undefined` if the user has not yet drawn it.
   */
  tipPoint?: Vector3;

  /**
   * The user completes the edit of the control points.
   */
  changeFlatteningMode: (mode: FlattenMode) => void;

  /**
   * The user wants to set/reset the alignment points (throat and/or tip).
   */
  setFlatteningAlignmentPoints: (throat?: Vector3, tip?: Vector3) => void;
}

/**
 * This component allows to:
 * - set the distance between alignment points (throat point and tip point);
 * - reset the alignment points.
 */
export const FlatteningAlignmentPoints = (props: IProps) => {
  const {
    changeFlatteningMode,
    setFlatteningAlignmentPoints,
    throatPoint,
    tipPoint,
  } = props;

  const onClickPrimaryButton = () => {
    changeFlatteningMode(FlattenMode.draw);
  };

  const onClickResetButton = () => {
    setFlatteningAlignmentPoints(undefined, undefined);
  };

  const isThroatPointUnavailable = throatPoint ? false : true;
  const isTipPointUnavailable = tipPoint ? false : true;

  const isResetDisabled = isThroatPointUnavailable && isTipPointUnavailable;
  const isConfirmationDisabled =
    isThroatPointUnavailable || isTipPointUnavailable;

  const throatColor = "#" + flatteningPointsPalette.get("throat")!.getHexString();
  const tipColor = "#" + flatteningPointsPalette.get("tip")!.getHexString();

  return (
    <React.Fragment>
      <ToolButton
        disabled={isResetDisabled}
        iconName={IconName.Reset}
        onClick={onClickResetButton}
      >
        {"Reset points"}
      </ToolButton>
      <div>
        <Chip disabled={isThroatPointUnavailable} iconColor={throatColor}>
          {"Throat"}
        </Chip>
        <Chip disabled={isTipPointUnavailable} iconColor={tipColor}>
          {"Tip"}
        </Chip>
      </div>
      <PrimaryButton
        disabled={isConfirmationDisabled}
        onClick={onClickPrimaryButton}
      >
        {"Confirm"}
      </PrimaryButton>
    </React.Fragment>
  );
};
