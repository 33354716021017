import { initClient } from "cadius-backend";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { initializeGeo3d } from "cadius-geo3d";

import { App } from "./containers/App";
import { initStore } from "./store";

import "./index.css";

declare const DEF_DEBUG_MODE: boolean;
if (DEF_DEBUG_MODE) {
  console.warn(`--- FLAG DEBUG MODE: ${DEF_DEBUG_MODE} ---`);
}

initializeGeo3d().then(async () => {
  await initClient();

  ReactDOM.render(
    <Provider store={initStore(DEF_DEBUG_MODE)}>
      <App />
    </Provider>,
    document.getElementById("root") as HTMLElement
  );
});
