import { GraphicalEntities, IconName, IItemProps } from "cadius-components";
import React from "react";

import { FlatCurvesNames } from "../reducers/support/flatten";

interface IProps {
  items: IItemProps[];
  resetFlattenCurve: (curveName: FlatCurvesNames) => void;
  startDrawingFlattenCurve: (curveName: FlatCurvesNames) => void;
  startEditingFlattenCurve: (curveName: FlatCurvesNames) => void;
}

export class FlatteningLines extends React.Component<IProps> {
  public render() {
    return <GraphicalEntities items={this.props.items} onClick={this.onClick} />;
  }

  private onClick = (curveName: string | number, icon?: IconName) => {
    if (icon === IconName.New) {
      this.props.startDrawingFlattenCurve(curveName as FlatCurvesNames);
    } else if (icon === IconName.Delete) {
      this.props.resetFlattenCurve(curveName as FlatCurvesNames);
    } else if (icon === undefined) {
      this.props.startEditingFlattenCurve(curveName as FlatCurvesNames);
    } else {
      throw new Error(
        `icon is ${icon}. There are no actions associated with this icon in the flatten section of the app.`
      );
    }
  };
}
